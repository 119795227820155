import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Api from './api';
import historyActions from './actions';
import { logger } from '../../helpers/debugLogger';
import { RootState } from '../store';
import {
	HISTORY_FILTERS,
	IBalanceAdjustmentItem,
	ICasinoBonusesItem,
	ICasinoDepositItem,
	ICasinoFilters,
	ICasinoHistoryItem,
	ICasinoSpinItem,
	IHistoryFilters,
	IHistoryStore,
	IHistoryWithdrawalItem,
} from './types';
import { historyReducers } from './slice';
import { AxiosApiResponse } from '../../service/types';
import { ISagaActionType } from '../types';
import { getHeaderTotalCount } from '../utils';
import { NumberOrNull } from '../../types/types';

const API = new Api();

const getStore = (state: RootState): IHistoryStore => {
	const { History } = state;
	const { filters } = History.casinoHistory;

	// @ts-expect-error FIXME: Property 'status_id' does not exist on type 'IHistoryFilters'.
	const casinoFilters: ICasinoFilters = { ...filters, status_id: filters.status_id ? [filters.status_id] : null };
	return {
		casinoFilters,
		bonusesFilters          : History.casinoBonuses.filters,
		spinsFilters            : History.casinoSpins.filters,
		depositFilters          : History.depositHistory.filters,
		withdrawalsFilters      : History.withdrawalsHistory.filters,
		balanceAdjustmentFilters: History.balanceAdjustmentHistory.filters,
		isFirstLoad             : History.balanceAdjustmentHistory.balanceAdjustmentExist,

	};
};
function* casinoHistory() {
	yield takeEvery(historyActions.GET_CASINO_HISTORY, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		const params: IHistoryFilters<number[] | NumberOrNull> = {
			...filters,
			status_id: filters.status_id ? [filters.status_id] : null,
		};
		yield put(historyReducers.setCasinoHistory({ filters }));
		try {
			yield put(historyReducers.setCasinoHistory({ UI: { loading: true } }));
			const response: AxiosApiResponse<ICasinoHistoryItem[]> = yield call(API.getCasinoHistory, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(historyReducers.setCasinoHistory({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(historyReducers.setCasinoHistory({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);

		}
	});

}

function* casinoHistoryBonus() {
	yield takeEvery(historyActions.GET_CASINO_BONUSES, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		try {
			yield put(historyReducers.setCasinoBonuses({ UI: { loading: true } }));
			const response: AxiosApiResponse<ICasinoBonusesItem[]> = yield call(API.getCasinoBonuses, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(historyReducers.setCasinoBonuses({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(historyReducers.setCasinoBonuses({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});

}

function* casinoHistorySpins() {
	yield takeEvery(historyActions.GET_CASINO_SPINS, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		try {
			yield put(historyReducers.setCasinoSpins({ UI: { loading: true } }));
			const response: AxiosApiResponse<ICasinoSpinItem[]> = yield call(API.getCasinoSpins, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(historyReducers.setCasinoSpins({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(historyReducers.setCasinoSpins({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});

}

function* depositHistory() {
	yield takeEvery(historyActions.GET_DEPOSIT_HISTORY, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		try {
			yield put(historyReducers.setDepositHistory({ UI: { loading: true } }));
			const response: AxiosApiResponse<ICasinoDepositItem[]> = yield call(API.getDepositHistory, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(historyReducers.setDepositHistory({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(historyReducers.setDepositHistory({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});

}

function* withdrawalsHistory() {
	yield takeEvery(historyActions.GET_WITHDRAWALS_HISTORY, function* (action: ISagaActionType<IHistoryFilters>) {
		const filters = action.data;
		try {
			yield put(historyReducers.setWithdrawalHistory({ UI: { loading: true } }));
			const response: AxiosApiResponse<IHistoryWithdrawalItem[]> = yield call(API.getWithdrawalsHistory, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(historyReducers.setWithdrawalHistory({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
			}
		} catch (e) {
			yield put(historyReducers.setWithdrawalHistory({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});
}

function* balanceAdjustmentHistory () {
	yield takeEvery(historyActions.GET_BALANCE_ADJUSTMENT_HISTORY, function* (action: ISagaActionType<IHistoryFilters>) {
		const { isFirstLoad }: IHistoryStore = yield select(getStore);
		const filters = action.data;
		try {
			yield put(historyReducers.setBalanceAdjustmentHistory({ UI: { loading: true } }));
			const response: AxiosApiResponse<IBalanceAdjustmentItem[]> = yield call(API.getBalanceAdjustmentHistory, filters);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(historyReducers.setBalanceAdjustmentHistory({ data, dataCount: getHeaderTotalCount(response), UI: { loading: false } }));
				if (!isFirstLoad) {
					yield put(historyReducers.setBalanceAdjustmentExist(true));
				}
			}
		} catch (e) {
			yield put(historyReducers.setBalanceAdjustmentHistory({ data: [], dataCount: 0, UI: { loading: false } }));
			logger.log(e);
		}
	});
}

function* setFilters() {
	yield takeLatest(historyActions.SET_FILTERS, function* (action: ISagaActionType<{ type: HISTORY_FILTERS; filters: IHistoryFilters  }>) {
		try {
			const { type, filters } = action.data;
			if (type === HISTORY_FILTERS.BALANCE_ADJUSTMENT_HISTORY) {
				logger.log('balance adjustment');
			}
			yield put(historyReducers.filterRefresh({ type, filter: filters }));
			switch (type) {
				case HISTORY_FILTERS.CASINO_HISTORY : {
					yield put(historyActions.getCasinoHistory(filters));
					break;
				}
				case HISTORY_FILTERS.CASINO_BONUSES: {
					yield put(historyActions.getCasinoBonuses(filters));
					break;
				}
				case HISTORY_FILTERS.CASINO_SPINS : {
					yield put(historyActions.getCasinoSpins(filters));
					break;
				}
				case HISTORY_FILTERS.DEPOSIT_HISTORY : {
					yield put(historyActions.getDepositHistory(filters));
					break;
				}
				case HISTORY_FILTERS.WITHDRAWALS_HISTORY : {
					yield put(historyActions.getWithdrawalsHistory(filters));
					break;
				}
				case HISTORY_FILTERS.BALANCE_ADJUSTMENT_HISTORY : {
					yield put(historyActions.getBalanceAdjustmentHistory(filters));
					break;
				}
				default:
					throw new Error('Unknown history type');
			}

		} catch (e) {
			logger.log(e);
		}
	});
}

function* finishTransaction() {
	yield takeEvery(historyActions.FINISH_TRANSACTION, function* (action: ISagaActionType<{ id: number }>) {
		try {
			const { data } = action;
			const response: AxiosApiResponse<{ url: string }> = yield call(API.finishTransaction, data);
			if (response && response.status === 200) {
				if (response.data.data.url) {
					window.location.href = `${response.data.data.url}`;
				}
			}
		} catch (e) {
			// yield put(modalsActions.setInfoUiAction({ visible: true, type: 'reject', description: e.message }));
		}
	});

}

function* historySaga() {
	yield all([
		fork(casinoHistory),
		fork(casinoHistoryBonus),
		fork(casinoHistorySpins),
		fork(depositHistory),
		fork(withdrawalsHistory),
		fork(balanceAdjustmentHistory),
		fork(finishTransaction),
		fork(setFilters),
	]);
}

export default historySaga;
