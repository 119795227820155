import React, { useState, Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import NotificationItem from './notification-item';
import actions from '../../redux/notifications/actions';

import { notif_count_per_page } from '../../config';
import NoData from '../no-data';
import { notificationsSelectors } from '../../redux/notifications/selectors';
import { useAppSelector } from '../../hooks/storeHooks';
import { IGetNotificationPayload } from '../../redux/notifications/types';

const loader = () => {
	return (
		<div className="loader">
			<img src="/resources/images/svg/loader.svg" alt="" title=""/>
		</div>
	);
};

const {
	selectMeta,
	selectData,
	selectUI,
} = notificationsSelectors;

const NotificationsList = () => {
	const { unread, total } = useAppSelector(selectMeta);
	const data = useAppSelector(selectData);
	const { loading } = useAppSelector(selectUI);

	const [page, setPage] = useState(1);
	const dispatch = useDispatch();

	const fetchData = () => {
		setPage(page + 1);
	};

	const getNotifications = (set: boolean) => {
		const limit = notif_count_per_page;
		dispatch(actions.getNotificationsAction({ payload: { page, limit }, set }));
	};

	useEffect(() => {
		getNotifications(true);
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		if (page > 1) {
			getNotifications(false);
		}
		// eslint-disable-next-line
	}, [page, dispatch]);


	const hasMore = data.length < total;
	const dataLength = data.length;

	const markAllRead = () => {
		const param: IGetNotificationPayload = {
			payload: { page: 1, limit: data.length },
			set    : true,
		};
		dispatch(actions.markAllReadAction(param));
	};

	const handleInfiniteScroll = () => {
		if (hasMore) {
			fetchData();
		}
	};

	return (
		<div className="nots_content">
			{
				!loading && !dataLength ?
					<>
						<h2 style={{ margin: '5px' }} className="page_title"><FormattedMessage id="notifications"/></h2>
						<NoData/>
					</>
					:
					<Fragment>
						<div className="head_block">
							<h2 className="page_title"><FormattedMessage id="notifications"/></h2>
							<div className="nots_description"><FormattedMessage id="nots_description"/></div>
							{unread ?
								<button className="mark_read" onClick={()=> markAllRead()}>
									<FormattedMessage id="mark_all_us_read"/>
								</button> : null
							}
						</div>
						<div className="nots_list" id="scrollableDiv">
							{!dataLength ? loader() :
								<InfiniteScroll
									style={{ height: '75vh' }}
									height={80}
									dataLength={dataLength}
									next={handleInfiniteScroll}
									hasMore={hasMore}
									loader={loading && loader()}
								>
									<ul> { data.map((item, idx) => (<NotificationItem key={`list-item-${idx}`} item={item}/>)) } </ul>
								</InfiniteScroll>
							}
						</div>
					</Fragment>
			}
		</div>
	);
};

export default NotificationsList;
