import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ISwitchesProps, SWITCH_TYPES } from './types';
import Switch from '@mui/material/Switch';
import { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import './switches.scss';

const Switches: React.FC<ISwitchesProps> = (props) => {
	const {
		type,
		label,
		customStyle,
		customClass = '',
		defaultChecked,
		labelPlacement = 'start',
		handleChange,
		disabled = false,
		value,
	} = props;

	const renderSwitches = () => {
		switch (type) {
			case SWITCH_TYPES.SUN_AND_MOON : {
				return (
					<FormControlLabel
						className={`material_formControl_label ${customClass}`}
						control={<Switch className='MaterialUISwitch' sx={customStyle} defaultChecked={defaultChecked} />}
						label={label}
						labelPlacement={labelPlacement}
						onChange={(e: SyntheticEvent, value: boolean) => handleChange(value, e)}
						disabled={disabled}
						checked={value}
					/>
				);
			}
			case SWITCH_TYPES.ANDROID : {
				return (
					<FormControlLabel
						className={`material_formControl_label ${customClass}`}
						control={<Switch className='Android12Switch' sx={customStyle} defaultChecked={defaultChecked} />}
						label={label}
						labelPlacement={labelPlacement}
						onChange={(e: SyntheticEvent, value: boolean) => handleChange(value, e)}
						disabled={disabled}
					/>
				);
			}
			case SWITCH_TYPES.IOS : {
				return (
					<FormControlLabel
						className={`material_formControl_label ${customClass}`}
						control={<Switch className='IOSSwitch' sx={customStyle} defaultChecked={defaultChecked} />}
						label={label}
						labelPlacement={labelPlacement}
						onChange={(e: SyntheticEvent, value: boolean) => handleChange(value, e)}
						disabled={disabled}
						checked={value}
					/>
				);
			}
			case SWITCH_TYPES.ON_OFF : {
				return (
					<Stack direction="row" spacing={1} alignItems="center">
						<Typography>
							<FormattedMessage id='off' />
						</Typography>
						<Switch
							className='AntSwitch'
							defaultChecked={defaultChecked}
							inputProps={{ 'aria-label': 'ant design' }}
							onChange={(e:SyntheticEvent, value: boolean) => handleChange(value, e)}
							disabled={disabled}
						/>
						<Typography>
							<FormattedMessage id = 'on' />
						</Typography>
					</Stack>
				);
			}
			default:
				break;
		}

		return null;
	};

	const switches = renderSwitches();
	return <FormGroup>{switches}</FormGroup>;
};

export default Switches;
