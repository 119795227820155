import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// import Chat from '../../../components/controls/live-chat';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import { helpReducers } from '../../../redux/help/slice';

import './bottom-menu.scss';
import { ROUTE_PATH } from '../../../routes/utils';
import { CHAT_VIEW_STATUS } from '../../../redux/help/types';
import { useAppSelector } from '../../../hooks/storeHooks';
import { profileSelectors } from '../../../redux/profile/selectors';

const {
	selectAuthToken,
} = profileSelectors;

const BottomMenu = () => {
	const dispatch = useDispatch();

	const authToken = useAppSelector(selectAuthToken);

	const layout = useSelector(({ Settings }) => Settings.layout);

	if (layout === 'promotion') {
		return false;
	}


	const handleChatOpen = () => {
		dispatch(helpReducers.setChat(CHAT_VIEW_STATUS.OPEN));
	};
	return (
		<>
			{
				authToken ?
					<>
						{
							layout === 'game' ? <button className="help_btn icon_help" onClick={handleChatOpen} > <FormattedMessage id="help" /> </button> :
								<>
									<Desktop>
										<button className="help_btn icon_help" onClick={handleChatOpen}>
											<FormattedMessage id="help" />
											{/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
										</button>
									</Desktop>
									<NotDesktop>
										<div className="bottom_menu" >
											<ul className="menu_list">
												<li><NavLink to={`${ROUTE_PATH.profile}/${ROUTE_PATH.bonus}/`} className="icon_gift"><FormattedMessage id="promotions" /></NavLink></li>
												<li><NavLink to={ROUTE_PATH.profile} className="icon_profile"><FormattedMessage id="profile.title" /></NavLink></li>

												<li><NavLink to={`${ROUTE_PATH.deposit}/`} className="icon_deposit"><FormattedMessage id="deposit.capitalize" /></NavLink></li>
												<li>
													<button className="help_btn icon_help" onClick={handleChatOpen}>
														<FormattedMessage id="help" />
														{/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
													</button>
												</li>
											</ul>
										</div>
									</NotDesktop>
								</>
						}
					</> :
					<button className="help_btn icon_help" onClick={handleChatOpen}>
						<FormattedMessage id="help" />
						{/* <NavLink className="help_btn icon_help" to="/help"><FormattedMessage id="help" /></NavLink> */}
					</button>
			}
			{/*<Chat />*/}

		</>
	);
};

export default BottomMenu;
