import {
	IActions,
	IBannersCallData,
	IBannersCallDataData,
	IGetSettingsPayload,
} from './types';


const actions = {
	GET_SCRIPTS         : 'GET_SCRIPTS',
	GET_LANGUAGE        : 'GET_LANGUAGE',
	APP_LANGUAGE_REFRESH: 'APP_LANGUAGE_REFRESH',
	OPEN_MODAL          : 'OPEN_MODAL',
	CLOSE_MODAL         : 'CLOSE_MODAL',
	CONFIGURING_APP     : 'CONFIGURING_APP',
	GET_CURRENCIES      : 'GET_CURRENCIES',
	LAYOUT_UPDATE       : 'LAYOUT_UPDATE',
	GET_INITIAL_SETTINGS: 'GET_INITIAL_SETTINGS',
	GET_SLIDER          : 'GET_SLIDER',
	GET_COUNTRIES       : 'GET_COUNTRIES',
	LOADER_UI           : 'LOADER_UI',
	GET_SETTINGS_UI     : 'GET_SETTINGS_UI',

	getScriptsAction: (): IActions => {
		return {
			type: actions.GET_SCRIPTS,
		};
	},

	getInitialSettingsAction: (data: IGetSettingsPayload) => {
		return {
			type: actions.GET_INITIAL_SETTINGS,
			data,
		};
	},

	getCurrenciesAction: (): IActions => {
		return {
			type: actions.GET_CURRENCIES,
		};
	},

	// updateLayoutAction: (data: TLayout): IActionsLayout => {
	// 	return {
	// 		type: actions.LAYOUT_UPDATE,
	// 		data: data
	// 	};
	// },

	getBannersAction: (data: IBannersCallDataData): IBannersCallData => {
		return {
			type: actions.GET_SLIDER,
			data,
		};
	},

	getCountriesAction: (): IActions => {
		return {
			type: actions.GET_COUNTRIES,
		};
	},
};

export default actions;
