import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import actions from '../../redux/notifications/actions';
import { modalReducers } from '../../redux/modals/slice';
import { BONUS_ITEMS_PER_PAGE, absolutePath, notificationsTypes } from '../../helpers/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import Date from '../controls/date';
import { typeDate } from '../controls/date/utils';
import { bonusSelectors } from '../../redux/bonus/selectors';
import { bonusReducers } from '../../redux/bonus/slice';
import { notificationsSelectors } from '../../redux/notifications/selectors';
import { notificationReducers } from '../../redux/notifications/slice';
import React from 'react';
import { INotificationItemProps } from './types';
import { INotification } from '../../redux/notifications/types';

const {
	selectBonusData,
} = bonusSelectors;
const {
	setInfoUI,
} = modalReducers;
const {
	setUI,
} = bonusReducers;
const {
	selectUI,
} = notificationsSelectors;
const {
	setUI: setNotificationUI,
} = notificationReducers;
const NotificationItem: React.FC<INotificationItemProps> = ({ item }) => {

	const bonusData = useAppSelector(selectBonusData);
	const { markAllLoading } = useAppSelector(selectUI);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { locale } = useParams();
	const { pathname } = useLocation();


	const markRead = ({ id, bonusID, seen, loading, type }: INotification) => {
		if (loading || markAllLoading){
			return;
		}

		!seen && dispatch(actions.markReadAction({ id }));
		if (Number(type) === notificationsTypes.bonusNotifications) {
			dispatch(setNotificationUI({ dropDownVisible: false }));

			const bonusPage = `/${locale}/profile/bonus#${bonusID}`;
			const index = bonusData?.findIndex(item => item.id === bonusID);
			const position = index + 1;

			if (index < 0) {
				dispatch(setUI({ showError: true }));
				dispatch(setInfoUI({
					visible    : true,
					type       : 'warning',
					description: 'not_founded_bonus_warning_description',
				}));
			}
			if (position > BONUS_ITEMS_PER_PAGE) {
				const page = Math.ceil(position / BONUS_ITEMS_PER_PAGE);
				dispatch(setUI({ page }));
			}
			if (pathname !== bonusPage) {
				navigate(bonusPage);
			}
		}
	};

	const ItemLink = item.action && absolutePath(item.action)
		? <a className={'menu_item'} rel="noopener noreferrer" href={item.action} target='_blank'>{item.title}</a>
		: item.action && !absolutePath(item.action)
			? <Link className={'menu_item'} to={item.action}>{item.title}</Link>
			: <div className={'menu_item'}>{item.title}</div>;

	return (
		<li onClick={()=> markRead(item)} className={!item.seen ? 'new_income' : ''}>
			{item.img ? <img src={`${item.img}`} alt="" title=""/> : null}
			<div className="not_info">
				{ItemLink}
				<p className='desc_menu_item'>{item.body && item.body}</p>
				<div className="not_date"><Date date={item.created} type={typeDate.DIFF_DATE}/></div>
			</div>
		</li>
	);
};

export default NotificationItem;
