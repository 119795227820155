import React from 'react';
import { NotDesktop } from '../../../helpers/devices';
import LangSwitcher from '../../lang-switcher';
import './sidebar-footer.scss';


const SidebarFooter: React.FC = () => {
	return (
		<div className="sidebar_footer">
			<NotDesktop>
				<LangSwitcher/>
			</NotDesktop>
		</div>
	);
};

export default SidebarFooter;
