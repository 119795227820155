import { SxProps, Theme } from '@mui/material';
import { SyntheticEvent } from 'react';

export interface ISwitchesProps {
	type             : SWITCH_TYPES;
	label?           : string;
	customStyle?     : SxProps<Theme>;
	customClass?     : string;
	defaultChecked ? : boolean;
	labelPlacement?  : 'end' | 'start' | 'top' | 'bottom';
	handleChange     : (value: boolean, event?: SyntheticEvent) => void;
	disabled        ?: boolean;
	value           ?: boolean;
}

export enum SWITCH_TYPES {
	SUN_AND_MOON = 'sunAndMoon',
	ANDROID      = 'android',
	IOS          = 'IOS',
	ON_OFF       = 'onOff',
}
