import { adaptSeo } from '../settings/utils';
import { IBottomMenu, IMenuColumnResponse, IMenuColumns, IMenuItem, IMenuResponse, ISitemap } from './types';

export const adaptSeoList = (list = []) => {
	if (!Array.isArray(list)) {
		return null;
	}
	const seoList =  list.map(item => adaptSeo(item));
	if (!seoList.length) {
		// @ts-expect-error FIXME: change adaptSEO arg types
		return [adaptSeo(null)];
	}

	return seoList;
};


export const formatMenuUrl = <T extends IMenuItem>(data: T[], urlField: keyof T, labelField: keyof T): Record<string, ISitemap> => {
	return data.reduce((acc: Record<string, ISitemap>, item: T) => {
		const url = item[urlField] as unknown as string;
		const label = item[labelField] as unknown as string;
		const arr = url.split('/');
		acc[arr[arr.length - 1]] = { url, label };
		return acc;
	}, {});
};


export const adaptBottomMenu = (data: IMenuResponse): IBottomMenu => {
	const { items, columns } = data;
	const adaptedColumns: IMenuColumns[] = columns.map((item: IMenuColumnResponse) => {
		const { id } = item;
		const menuItems = items.filter((el: IMenuItem) => el.column_id === id);

		return {
			...item,
			menuItems: menuItems,
		};
	});

	return {
		columns: adaptedColumns,
		items  : items,
	};
};