import { RootState } from '../store';


export const selectSettings = (state: RootState) => state.Settings;
export const settingsInitial = (state: RootState) => state.Settings.initialSettings;
export const settingsUI = (state: RootState) => state.Settings.UI;
export const selectSelectedSubIDs = (state: RootState) => state.Settings.selectedSubIDs;
export const selectCurrencies = (state: RootState) => state.Settings.currencies;
export const getSettingsUI = (state: RootState) => state.Settings.UI;
export const selectSettingsSidebarBlog = (state: RootState) => state.Settings.sidebar_blog;
export const selectSettingsSlider = (state: RootState) => state.Settings.slider;
export const selectSettingsCurrentLanguage = (state: RootState) => state.Settings.current_language;
