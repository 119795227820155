import {
	ICategory,
	ICurrentGame,
	IGame,
	IGameGroupParam,
	IGameListParam,
	IGamesInitialState,
	IGameUI,
	IProvider,
	IProviderParam,
} from './types';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { NumberOrNull } from '../../types/types';

const initialState: IGamesInitialState = {
	searchedGames    : null,
	searchedHomeGames: [],
	categories       : [],
	sub_categories   : [],
	providers        : [],
	filters          : {
		home_selected_providers : [],
		games_selected_providers: [],
		search_term             : '',
	},
	search_term       : '',
	mobile_search_term: '',
	game              : {
		alias: '',
		demo : false,
		url  : '',
	},
	games         : null,
	showMore      : false,
	similar       : [],
	page_number   : 1,
	dataCount     : 0,
	unClearedGames: null,
	UI            : {
		loading              : false,
		providerLoading      : false,
		customProviderLoading: false,
		gameListLoading      : false,
		hasExitedGame        : false,
	},
	section           : '',
	selected_providers: [],
};

const games = createSlice({
	name    : 'games',
	initialState,
	reducers: {
		setSearchedGamesAction(state: Draft<IGamesInitialState>, action: PayloadAction<IGame[]>) {
			state.searchedGames = action.payload;
		},
		setHomeSearchedGamesAction(state: Draft<IGamesInitialState>, action: PayloadAction<IGame[]>) {
			state.searchedHomeGames = action.payload;
		},
		setUnClearedGames(state: Draft<IGamesInitialState>, action: PayloadAction<null>) {
			state.unClearedGames = action.payload;
		},
		setGamesListAction(state: Draft<IGamesInitialState>, action: PayloadAction<IGameListParam>) {
			const { action: _action, data, dataCount } = action.payload;

			if (!data || !data.length) return;

			let list = null;
			if (_action === 'add_games') {
				list = state.games && state.games.length > 0
					? [...state.games, ...data]
					: data;
			} else {
				list = data;
			}

			state.games = list;
			if (dataCount) {
				state.dataCount = dataCount;
			}
		},
		setCategoriesAction(state: Draft<IGamesInitialState>, action: PayloadAction<ICategory[]>) {
			state.categories = action.payload;
		},
		setSubCategoriesAction(state: Draft<IGamesInitialState>, action: PayloadAction<ICategory[]>) {
			state.sub_categories = action.payload;
		},
		setProvidersAction(state: Draft<IGamesInitialState>, action: PayloadAction<IProvider[]>) {
			state.providers = action.payload;
		},
		selectProviderAction(state: Draft<IGamesInitialState>, action: PayloadAction<IProviderParam>) {
			const { section, data } = action.payload;
			state.filters = {
				...state.filters,
				[`${section}_selected_providers`]: [...data],
			};
		},
		selectCategoryAction(state: Draft<IGamesInitialState>, action: PayloadAction<NumberOrNull>) {
			const { payload } = action;
			const { selected_category } = state.filters;
			const category = !payload && selected_category ? selected_category : payload ? payload : state.categories[0].id;
			state.filters = Object.assign({}, state.filters, { selected_category: category });
		},
		setGameNameAction(state: Draft<IGamesInitialState>, action: PayloadAction<string>) {
			state.filters.search_term = action.payload;
		},
		setSearchTermAction(state: Draft<IGamesInitialState>, action: PayloadAction<string>) {
			state.search_term = action.payload;
		},
		setMobileSearchTermAction(state: Draft<IGamesInitialState>, action: PayloadAction<string>) {
			state.mobile_search_term = action.payload;
		},
		setGameAction(state: Draft<IGamesInitialState>, action: PayloadAction<ICurrentGame | null>) {
			state.game = action.payload;
		},
		setGamesGroupsAction(state: Draft<IGamesInitialState>, action: PayloadAction<IGameGroupParam>) 	{
			state.games = action.payload.main;
			state.similar = action.payload.similar;
		},
		resetGamesGroup(state: Draft<IGamesInitialState>) {
			state.games = null;
		},
		setShowMore(state: Draft<IGamesInitialState>, action: PayloadAction<boolean>) {
			state.showMore = action.payload;
		},
		uiRefresh(state: Draft<IGamesInitialState>, action: PayloadAction<Partial<IGameUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		setPageNumberAction(state: Draft<IGamesInitialState>, action: PayloadAction<number>) {
			state.page_number = action.payload;
		},
	},
});


export const gameReducers = {
	setSearchedGamesAction    : games.actions.setSearchedGamesAction,
	setHomeSearchedGamesAction: games.actions.setHomeSearchedGamesAction,
	setUnClearedGames         : games.actions.setUnClearedGames,
	setGamesListAction        : games.actions.setGamesListAction,
	setCategoriesAction       : games.actions.setCategoriesAction,
	setSubCategoriesAction    : games.actions.setSubCategoriesAction,
	setProvidersAction        : games.actions.setProvidersAction,
	selectProviderAction      : games.actions.selectProviderAction,
	selectCategoryAction      : games.actions.selectCategoryAction,
	setGameNameAction         : games.actions.setGameNameAction,
	setSearchTermAction       : games.actions.setSearchTermAction,
	setMobileSearchTermAction : games.actions.setMobileSearchTermAction,
	setGameAction             : games.actions.setGameAction,
	setGamesGroupsAction      : games.actions.setGamesGroupsAction,
	resetGamesGroup           : games.actions.resetGamesGroup,
	setShowMore               : games.actions.setShowMore,
	setPageNumberAction       : games.actions.setPageNumberAction,
	uiRefresh                 : games.actions.uiRefresh,
};
export default games.reducer;
