import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import { adaptSeo, adaptListSeo } from '../settings/utils';
import { modalReducers } from '../modals/slice';
import { settingsReducers } from '../settings/slice';
import { menuReducers } from '../menu/slice';
import { gameReducers } from './slice';
import { buildSubCategoryParams, detectMediaSize, formatCategoriesUrl, sortCategories } from '../../helpers/utils';
import { APP_URL, CASHIER_URL } from '../../config';
import { SUBCATEGORY_TYPE } from '../../constants/common';
import { router } from '../../routes/browserRoutes';
import { routerReducers } from '../router/slice';
import { logger } from '../../helpers/debugLogger';
import { ErrorCodes } from '../../errors/ErrorCodes';
import { toInteger } from 'lodash';
import { ISagaActionType } from '../types';
import { AxiosApiResponse } from '../../service/types';
import {
	ICategoriesParam,
	ICategory,
	IGame, IGameBaseParams, IGameInfoPayload, IGameInfoResponse, IGameListParam,
	IGameParams,
	IGameSearchPayload,
	IGamesGetStore, IGameStartPayload, IGameStartResponse,
	IProviderResponse,
} from './types';
import { RootState } from '../store';
import { PROVIDER_DISPLAY_TYPE } from '../../components/layout/providers/types';
import { NumberOrNull } from '../../types/types';

const API = new Api();

const {
	setHistory,
	setNavigateEnd,
} = routerReducers;

const getStoreData = ({ Games, Settings, Profile }: RootState): IGamesGetStore => ({
	authToken       : Profile.authToken,
	storeGames      : Games.games || [],
	subCategoryType : Settings.initialSettings.sub_category_type ||  PROVIDER_DISPLAY_TYPE.PROVIDER,
	currentLanguage : Settings.current_language?.code,
	selectedCategory: Settings.selectedCategory,
	selectedSubIDs  : Settings.selectedSubIDs,
});


function* getCategories() {
	yield takeLatest(actions.GET_CATEGORIES, function* (action: ISagaActionType<ICategoriesParam>) {
		const { categories, withNavigate = false } = action.data;
		const { selectedCategory, currentLanguage } = yield select(getStoreData);

		try {
			const result: AxiosApiResponse<ICategory[]> = yield call(API.getCategoriesList);

			if (result && result.status === 200) {
				const data: ICategory[] = sortCategories(result.data.data);
				const sitemap = formatCategoriesUrl(data, 'alias', 'name');

				const selectedCategoryCandidate = data.find(cat => cat.alias === categories.toString());
				const params: { categories: NumberOrNull } = {
					categories: null,
				};

				if (!categories && data.length > 0) {
					yield put(settingsReducers.setSelectedCategory(data[0].category));
					yield put(gameReducers.selectCategoryAction(data[0].category));
				}

				if (selectedCategoryCandidate) {
					params.categories = selectedCategoryCandidate.category;
					yield put(gameReducers.selectCategoryAction(selectedCategoryCandidate.category));
					yield put(settingsReducers.setSelectedCategory(selectedCategoryCandidate.category));
				} else if (data && data.length > 0) {
					const [firstCategory] = data;
					params.categories = firstCategory.category;
				}

				const foundOldCategory = data.find(cat => cat.category === selectedCategory );

				if (foundOldCategory && withNavigate) {
					if (typeof params.categories === 'number') {
						yield put(settingsReducers.setSelectedCategory(params.categories));
					}

					const search = window.location.search;
					const navigateTo = search ? `/${currentLanguage}/${foundOldCategory.alias}${search}` : `/${currentLanguage}/${foundOldCategory.alias}`;
					yield call(router.navigate, navigateTo);
				}

				const adaptedSeo = adaptListSeo(result.data.data, decodeURIComponent(window.location.pathname));
				yield put(settingsReducers.setSEOData(adaptedSeo));
				// @ts-expect-error FIXME: fix the return type of formatCategories url
				yield put(menuReducers.setSitemap(sitemap));
				yield put(gameReducers.setCategoriesAction(data));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}

function* getSubCategories() {
	yield takeLatest(actions.GET_SUB_CATEGORIES, function* (action: ISagaActionType<ICategoriesParam>) {
		const { subCategoryType, selectedSubIDs } = yield select(getStoreData);
		const { withNavigate, categories } = action.data;

		if (subCategoryType === SUBCATEGORY_TYPE.customProviders) {
			yield put(gameReducers.uiRefresh({ customProviderLoading: true }));

			try {
				const result: AxiosApiResponse<ICategory[]> = yield call(API.getSubCategoriesList, { categories });

				if (result && result.status === 200) {
					yield put(gameReducers.setSubCategoriesAction(result.data.data));
					const subCategoryCandidates: string[] = [];

					result.data.data.forEach((subCategory) => {
						if (selectedSubIDs.includes(subCategory.id)) {
							subCategoryCandidates.push(subCategory.alias);
						}
					});

					if (withNavigate) {
						const builtQueryString = buildSubCategoryParams(subCategoryCandidates);
						if (builtQueryString) {
							yield call(router.navigate, {
								search: `?${builtQueryString}`,
							});
						}
					}

				}
			} catch (e) {
				logger.log('e', e);
			}

			yield put(gameReducers.uiRefresh({ customProviderLoading: false }));
		}
	});

}

function* selectCategory() {
	yield takeLatest(actions.SELECT_CATEGORY, function* (action: ISagaActionType<{ data: number }>) {
		const { subCategoryType } = yield select(getStoreData);

		try {
			if (subCategoryType === SUBCATEGORY_TYPE.providers) {
				yield put(actions.getProvidersAction({ categories: [action.data] }));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}

function* getProviders() {
	yield takeLatest(actions.GET_PROVIDERS, function* (action: ISagaActionType<number>) {
		yield put(gameReducers.uiRefresh({ providerLoading: true }));

		try {
			yield put(gameReducers.setProvidersAction([]));

			// @ts-expect-error FIXME: fix this
			const result: AxiosApiResponse<IProviderResponse[]> = yield call(API.getProvidersList, action.data);

			if (result && result.status === 200) {
				const { data } = result.data;

				yield put(gameReducers.setProvidersAction(data.map(item => ({ id: item.provider_id, ...item }))));
			}
		} catch (e) {
			logger.log(e);
		}

		yield put(gameReducers.uiRefresh({ providerLoading: false }));

	});

}

function* searchGames() {
	yield takeLatest(actions.SEARCH_GAMES, function* (action: ISagaActionType<IGameSearchPayload>) {
		yield put(gameReducers.setSearchedGamesAction([]));

		try {
			const { data } = action;
			const params: IGameParams = {
				...data,
				sort_by   : 'play_count',
				channel_id: 1,
			};

			const result: AxiosApiResponse<IGame[]> = yield call(API.getGamesList, params);

			if (result && result.status === 200) {
				yield put(gameReducers.setSearchTermAction(data.name));
				yield put(gameReducers.setSearchedGamesAction(result.data.data));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}

function* searchHomeGames() {
	yield takeLatest(actions.SEARCH_HOME_GAMES, function* (action: ISagaActionType<IGameSearchPayload>) {
		yield put(gameReducers.setHomeSearchedGamesAction([]));

		try {
			const { data } = action;
			const params: IGameParams = {
				...data,
				sort_by   : 'play_count',
				channel_id: 1,
			};

			const result: AxiosApiResponse<IGame[]> = yield call(API.getGamesList, params);

			if (result && result.status === 200) {
				//localStorage.setItem("auth_token",result.data.data.user.auth_token)
				yield put(gameReducers.setHomeSearchedGamesAction(result.data.data));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});

}

function* getGamesList() {
	yield takeLatest(actions.GET_GAMES_LIST, function* (action: ISagaActionType<IGameListParam>) {
		yield put(gameReducers.uiRefresh({ gameListLoading: true }));

		try {
			const { data } = action;
			const { section = 'games' } = data;
			if (data.action === 'filter_games') yield put(gameReducers.setGamesListAction({ action: data.action, data: [] }));

			const params = {
				...data.params,
				category  : data.params?.category !== 423 ? data.params?.category : '',
				sort_by   : 'play_count',
				channel_id: 1,
			};

			// @ts-expect-error FIXME: fix this
			const result: AxiosApiResponse<IGame[]> = yield call(API.getGamesList, params);

			if (result && result.status === 200) {
				yield put(gameReducers.setGamesListAction({ action: data.action, data: result.data.data, section, dataCount: +result.headers['x-total-count'] }));
			}
		} catch (e) {
			logger.log('e', e);
		}

		yield put(gameReducers.uiRefresh({ gameListLoading: false }));
	});

}


function* getGamesGroups() {
	yield takeLatest(actions.GET_GAMES_GROUPS, function* (action: ISagaActionType<IGameBaseParams>) {
		yield put(gameReducers.uiRefresh({ loading: true }));

		const { storeGames } = yield select(getStoreData);

		try {
			const { data } = action;
			const params = {
				...data,
				category  : data.category !== 423 ? data.category : '',
				sort_by   : 'play_count',
				channel_id: 1,
			};

			const [resGames, similar] = yield all(
				[
					// @ts-expect-error FIXME: fix this
					call(API.getGamesList, params),
					// @ts-expect-error FIXME: fix this
					call(API.getSimilarGames, { category: data.category, lang_id: params.lang_id }),
				]
			);

			if (resGames && resGames.status === 200) {
				if (resGames.data.data.length < 18) {
					yield put(gameReducers.setShowMore(false));
				} else {
					yield put(gameReducers.setShowMore(true));
				}

				if (storeGames){
					yield put(gameReducers.setGamesGroupsAction({ main: storeGames.concat(resGames.data.data), similar: similar.data.data }));
				} else {
					yield put(gameReducers.setGamesGroupsAction({ main: resGames.data.data, similar: similar.data.data }));
				}

				yield put(gameReducers.uiRefresh({ loading: false }));
			}
		} catch (e) {
			yield put(gameReducers.setGamesGroupsAction({ main: null, similar: [] }));
			yield put(gameReducers.uiRefresh({ loading: false }));

			logger.log('e', e);
		}
	});

}

function* infoGame() {
	yield takeLatest(actions.INFO_GAME, function*(action: ISagaActionType<IGameInfoPayload>) {
		const { authToken } = yield select(getStoreData);

		try {
			const { data } = action;
			const params = data.casino_game_id ? { casino_game_id: data.casino_game_id } : { alias: data.alias };
			const resultInfo: AxiosApiResponse<IGameInfoResponse> = yield call(API.infoGame, params);

			if (resultInfo.status === 200){
				const { data: dataResultInfo } = resultInfo.data;
				const seo = adaptSeo(dataResultInfo);

				yield put(settingsReducers.setSEOData(seo));
				yield put(gameReducers.setGameAction({ name: dataResultInfo.name, description: dataResultInfo.description, url: '', error: null }));

				if (authToken) {
					yield put(actions.startGameAction({ alias: '' + dataResultInfo.alias || '' + data.alias, demo: false }));
				}
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = toInteger(e.message);
				yield put(gameReducers.setGameAction({ url: '', error: ErrorCodes[errorCode] }));
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}
	});

}


function* startGame() {
	yield takeLatest(actions.START_GAME, function* (action: ISagaActionType<IGameStartPayload>) {

		try {
			const { data } = action;
			const params = {
				...data,
				exit_url   : APP_URL,
				cashier_url: CASHIER_URL,
				https      : true,
			};

			const result: AxiosApiResponse<IGameStartResponse> = yield call(API.startGame, params);
			const device = detectMediaSize();

			if (result && result.status === 200) {
				const returnCashierURL = result.data.data.cashier_url;

				if (returnCashierURL) {
					yield put(setNavigateEnd(true));
					yield put(setHistory({ to: returnCashierURL, options: { replace: true } }));
					yield put(modalReducers.setDepositUI({ visible: true }));
				}

				if (device === 'mobile') {
					yield window.location.href = result.data.data.url;
				}

				const { url, name, description } = result.data.data;
				yield put(gameReducers.setGameAction({ url, name, description, error: null }));
			}
		} catch (e) {
			if (e instanceof Error) {
				const errorCode = toInteger(e.message);

				if (errorCode === ErrorCodes.RESPONSIBLE_GAMBLING_TIME_OUT_LIMIT) return;

				yield put(gameReducers.setGameAction({ url: '', error: ErrorCodes[errorCode] }));

				if (errorCode === ErrorCodes.INSUFFICIENT_BALANCE)
					yield put(modalReducers.setDepositUI({ visible: true }));
				else
					yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}
	});
}

function* profileSaga() {
	yield all([
		fork(searchGames),
		fork(searchHomeGames),
		fork(getCategories),
		fork(selectCategory),
		fork(getProviders),
		fork(getGamesList),
		fork(getGamesGroups),
		fork(startGame),
		fork(infoGame),
		fork(getSubCategories),
	]);
}

export default profileSaga;
