import { PartialConfigs } from '../../service/types';
import { apiRequest } from '../../service/service';
import { IBannerParams } from './types';

export default class Api {
	getBanners = (params: IBannerParams) => {
		const url = '/v1/content/banner';
		const config: PartialConfigs<void, IBannerParams> = {
			method: 'get',
			url   : url,
			params,
		};
		return apiRequest(config);
	};
}
