import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './clock.scss';


const Clock: React.FC = () => {
	const [currentTime, setCurrentTime] = useState(new Date());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(new Date());
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className={'time_block icon_clock'}>
			<p>{moment(currentTime).format('HH:mm')}</p>
		</div>
	);
};
export default Clock;
