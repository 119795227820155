import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './error-page.scss';
import { settingsReducers as settingsReducer } from '../../redux/settings/slice';
import { useAppDispatch } from '../../hooks/storeHooks';

const ErrorPage = (): ReactElement => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(settingsReducer.layoutUpdate('error'));
		return () => {
			dispatch(settingsReducer.layoutUpdate(''));
		};
	}, [dispatch]);

	return (
		<div className="error_page">
			<div className="error_number">
				<img src="/resources/images/svg/404.svg" alt="" title=""/>
			</div>
			<div className="page_title"><FormattedMessage id="error_title"/></div>
			<div className="page_description"><FormattedMessage id="error_description"/></div>
			<Link className="home_btn" to="/"><FormattedMessage id="go_to_home"/></Link>
		</div>
	);
};
export default ErrorPage;
