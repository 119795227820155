import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ROUTE_PATH } from '../../../routes/utils';
import './sidebar-user-actions.scss';

const SidebarUserActions = () => {
	return (
		<div className="buttons_block">
			<div className="inner_block">
				<Link className="icon_profile" to={ROUTE_PATH.profile}><span><FormattedMessage id="profile.title"/></span></Link>
			</div>
			<div className="inner_block">
				<Link className="icon_gift" to={`${ROUTE_PATH.promotions}/`}><span><FormattedMessage id="promotions"/></span></Link>
			</div>
		</div>
	);
};

export default SidebarUserActions;
