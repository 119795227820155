export enum SubscribeCodes  {
	BALANCE        = 8,
	LATEST_WINNERS = 13,
	TRONLINK_LOGIN = 31,
	TRONLINK_SIGN  = 32,
	DEACTIVATE     = 39,

	USER_UPDATE_BALANCE = 47,
	USER_UPDATE_BONUS_BALANCE = 48,

	NOTIF_BALANCE_ADJUSTMENT   = 34,
	NOTIF_CASHBACK = 35,
	NOTIF_USER_BONUS_SPIN = 46,
	NOTIF_USER_DOCUMENT_UPDATE = 49,

}
