import { RootState } from '../store';

export const gamesSelectors = {
	selectGames            : (state: RootState) => state.Games.games,
	selectSimilar          : (state: RootState) => state.Games.similar,
	selectGame             : (state: RootState) => state.Games.game,
	selectFilters          : (state: RootState) => state.Games.filters,
	selectCategories       : (state: RootState) => state.Games.categories,
	selectSubCategories    : (state: RootState) => state.Games.sub_categories,
	selectMobileSearchTerm : (state: RootState) => state.Games.mobile_search_term,
	selectSearchedGames    : (state: RootState) => state.Games.searchedGames,
	selectSearchedHomeGames: (state: RootState) => state.Games.searchedHomeGames,
	selectUI               : (state: RootState) => state.Games.UI,
	selectShowMore         : (state: RootState) => state.Games.showMore,
	selectPageNumber       : (state: RootState) => state.Games.page_number,
	selectDataCount        : (state: RootState) => state.Games.dataCount,
	selectProviders        : (state: RootState) => state.Games.providers,
	selectSelectedProviders: (state: RootState) => state.Games.selected_providers,
	// selectGameSearch       : (state: RootState) => state.Games.search,
	// selectSearchTerm           : (state: RootState) => state.Games.search_term,
	// selectHomeSelectedProviders: (state: RootState) => state.Games.filters.home_selected_providers,
	// selectGameSelectedProviders: (state: RootState) => state.Games.filters.games_selected_providers,
	// selectSelectedCategory     : (state: RootState) => state.Games.filters.selected_category,
	// selectCustomProviders      : (state: RootState) => state.Games.custom_providers,
	// selectPagination           : (state: RootState) => state.Games.pagination,
};
