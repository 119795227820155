import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import {
	PrivateRoutes, PublicRoutes,
	ROUTE_PATH,
} from './utils';
import Root from '../components/root/root';

import { RouterDistributor } from '../pages/routerDistributor/RouterDistributor';
import ErrorPage from '../pages/error-page';
import { ErrorElement } from '../components/errorBoundary/ErrorElement';


const getRoutes = (isAuth: boolean) => {
	return isAuth ? PrivateRoutes : PublicRoutes;
};

export const routerData = [
	{
		path        : ROUTE_PATH.root,
		exact       : true,
		element     : <RouterDistributor />,
		errorElement: <ErrorElement id={ROUTE_PATH.root} />,
	},
	{
		path        : ROUTE_PATH.errorPage,
		exact       : true,
		element     : <ErrorPage />,
		errorElement: <ErrorElement id={ROUTE_PATH.errorPage} />,

	},
	{
		path        : ROUTE_PATH.home,
		exact       : true,
		element     : <Root />,
		errorElement: <ErrorElement id={ROUTE_PATH.home} />,

		children: getRoutes(false), // This is a Template and changes at createRouterData function.
	},
	{
		path        : ROUTE_PATH.noMatch,
		exact       : true,
		element     : <Navigate to="/404/" /> ,
		errorElement: <ErrorElement id={ROUTE_PATH.noMatch} />,

	},

];


export const createRouterData = (authenticated: boolean) => {
	const data: RouteObject[] = routerData.map(item => {
		if (item.path === ROUTE_PATH.home) {
			return {
				...item,
				children: getRoutes(authenticated),
			};
		}
		return item;
	});
	return createBrowserRouter(data);
};

//  { baseName: 'en'}
const router = createBrowserRouter(routerData);


export { router };


