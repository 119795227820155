
import CategoryPreloader from './components/category-preloader';
import ProviderPreloader from './components/provider-preloader';
import GamesListPreloader from './components/games-list-preloader';
import GamesGroupPreloader from './components/games-group-preloader';
import ProfilePreloader from './components/profile-preloader';
import InboxPreloader from './components/inbox-preloader';
import HistoryPreloader from './components/history-preloader';
import PromotionPreloader from './components/promotion-preloader';
import SearchPreloader from './components/search-preloader';
import SearchedGamesPreloader from './components/search-list-preloader';
import MobileSGPreloader from './components/mobile-sg-preloader';
import {
	TournamentBannerPreloader,
	TournamentPrizePreloader,
} from './components';
import './preloader.scss';
import TournamentConditionsPreloader from './components/tournamentConditionsPreloader';
import React from 'react';
import { IPreloader, IPreloaderProps } from './types';

const Preloader: React.FC<IPreloaderProps> = (props) => {
	const { section, itemCount, className, headClassName, pageNumber } = props;
	const Preloader: IPreloader = {
		category  : <CategoryPreloader/>,
		provider  : <ProviderPreloader/>,
		gamesList : <GamesListPreloader />,
		gamesGroup: <GamesGroupPreloader
			itemCount       = {itemCount}
			className       = {className}
			headClassName   = {headClassName}
			pageNumber      = {pageNumber}
		/>,
		profile                  : <ProfilePreloader/>,
		inbox                    : <InboxPreloader/>,
		history                  : <HistoryPreloader/>,
		promotions               : <PromotionPreloader/>,
		search                   : <SearchPreloader/>,
		searchedGames            : <SearchedGamesPreloader/>,
		mobileSearchedGames      : <MobileSGPreloader/>,
		tournamentInnerBanner    : <TournamentBannerPreloader />,
		tournamentInnerPrize     : <TournamentPrizePreloader />,
		tournamentInnerConditions: <TournamentConditionsPreloader />,
	};
	return (
		<div className="preloader">
			{Preloader[section]}
		</div>
	);
};

export default Preloader;
