import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	IConfirmModal,
	IDeactivateModal,
	IDeleteAvatarModal, IDepositModal,
	IForgetModal, IInboxInnerModal, IInfoModal,
	ILoginModal,
	IModalInitialState, IReactivateModal,
	IRegisterModal,
	IResetModal, ITournamentInnerGames,
	ITournamentInnerTerms, ITwoFALoginModal, ITwoFAModal, IUploaderModal,
	IVerification,
} from './types';

const initialState: IModalInitialState = {
	Login     : { visible: false, loading: false, error: '', redirect: '', button: '', description: null, type: null },
	Register  : { visible: false, loading: false, error: '', button: '' },
	Reset     : { visible: false, loading: false, error: '', hash: '', success: false },
	Forget    : { visible: false, loading: false, error: '', success: false, hash: '' },
	InboxInner: { visible: false, loading: false, selectedLetter: null ,error: '' },
	Deactivate: { visible: false, loading: false, error: '' },
	Deposit   : { visible: false, loading: false },
	Info      : {
		visible    : false,
		loading    : false,
		navigate   : false,
		title      : null,
		description: null,
		type       : null,
		showLink   : false,
		linkTitle  : '',
		hrefTo     : '',
		message    : null,
	},
	Uploader            : { visible: false, loading: false, error: '' },
	DeleteAvatar        : { visible: false, loading: false },
	ReactivateModal     : { visible: false, loading: false, error: '' },
	TournamentInnerGames: { visible: false, loading: false },
	TournamentInnerTerms: { visible: false, loading: false },
	Verification        : { visible: false, loading: false },
	TwoFAModal          : { visible: false, loading: false },
	TwoFALoginModal     : { visible: false, loading: false },
	ConfirmModal        : { visible: false, loading: false, item: null },
};

const modal = createSlice({
	name        : 'modal',
	initialState: initialState,
	reducers    : {
		setLoginUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<ILoginModal>>) {
			const source = state.Login;
			const target = action.payload;
			state.Login = { ...source, ...target };
		},
		setRegisterUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IRegisterModal>>) {
			const source = state.Register;
			const target = action.payload;
			state.Register = { ...source, ...target };
		},
		setResetUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IResetModal>>) {
			const source = state.Reset;
			const target = action.payload;
			state.Reset = { ...source, ...target };
		},
		setForgetUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IForgetModal>>) {
			const source = state.Forget;
			const target = action.payload;
			state.Forget = { ...source, ...target };
		},
		setInboxInnerUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IInboxInnerModal>>) {
			const source = state.InboxInner;
			const target = action.payload;
			state.InboxInner = { ...source, ...target };
		},
		setDeactivateUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IDeactivateModal>>) {
			const source = state.Deactivate;
			const target = action.payload;
			state.Deactivate = { ...source, ...target };
		},
		setDepositUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IDepositModal>>) {
			const source = state.Deposit;
			const target = action.payload;
			state.Deposit = { ...source, ...target };
		},
		setInfoUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IInfoModal>>) {
			const source = state.Info;
			const target = action.payload;
			state.Info = { ...source, ...target };
		},
		setUploaderUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IUploaderModal>>) {
			const source = state.Uploader;
			const target = action.payload;
			state.Uploader = { ...source, ...target };
		},
		setDeleteAvatarUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IDeleteAvatarModal>>) {
			const source = state.DeleteAvatar;
			const target = action.payload;
			state.DeleteAvatar = { ...source, ...target };
		},
		setReactivateUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IReactivateModal>>) {
			const source = state.ReactivateModal;
			const target = action.payload;
			state.ReactivateModal = { ...source, ...target };
		},
		setTournamentInnerGamesUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<ITournamentInnerGames>>) {
			const source = state.TournamentInnerGames;
			const target = action.payload;
			state.TournamentInnerGames = { ...source, ...target };
		},
		setTournamentInnerTermsUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<ITournamentInnerTerms>>) {
			const source = state.TournamentInnerTerms;
			const target = action.payload;
			state.TournamentInnerTerms = { ...source, ...target };
		},
		setVerificationUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IVerification>>) {
			const source = state.Verification;
			const target = action.payload;
			state.Verification = { ...source, ...target };
		},
		setTwoFAModalUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<ITwoFAModal>>) {
			const source = state.TwoFAModal;
			const target = action.payload;
			state.TwoFAModal = { ...source, ...target };
		},
		setTwoFALoginModalUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<ITwoFALoginModal>>) {
			const source = state.TwoFALoginModal;
			const target = action.payload;
			state.TwoFALoginModal = { ...source, ...target };
		},
		setConfirmModalUI(state: Draft<IModalInitialState>, action: PayloadAction<Partial<IConfirmModal>>) {
			const source = state.ConfirmModal;
			const target = action.payload;
			state.ConfirmModal = { ...source, ...target };
		},
		resetModalState() {
			return initialState;
		},
	},
});

export const modalReducers = {
	setLoginUI               : modal.actions.setLoginUI,
	setRegisterUI            : modal.actions.setRegisterUI,
	setResetUI               : modal.actions.setResetUI,
	setForgetUI              : modal.actions.setForgetUI,
	setInboxInnerUI          : modal.actions.setInboxInnerUI,
	setDeactivateUI          : modal.actions.setDeactivateUI,
	setDepositUI             : modal.actions.setDepositUI,
	setInfoUI                : modal.actions.setInfoUI,
	setUploaderUI            : modal.actions.setUploaderUI,
	setDeleteAvatarUI        : modal.actions.setDeleteAvatarUI,
	setReactivateUI          : modal.actions.setReactivateUI,
	setTournamentInnerGamesUI: modal.actions.setTournamentInnerGamesUI,
	setTournamentInnerTermsUI: modal.actions.setTournamentInnerTermsUI,
	setVerificationUI        : modal.actions.setVerificationUI,
	resetModalState          : modal.actions.resetModalState,
	setTwoFAModalUI          : modal.actions.setTwoFAModalUI,
	setTwoFALoginModalUI     : modal.actions.setTwoFALoginModalUI,
	setConfirmModalUI        : modal.actions.setConfirmModalUI,

};


export default modal.reducer;
