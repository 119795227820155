const EXIT_MESSAGES = [
	{
		messageId: 'gameExit', // Endorphina
	},
	{
		message: 'GAME_CLOSE',  // RedRake
	},
	{
		type: 'gameClose',   // Habanero
	},
];

export const isExitEvent = (data: { [key in 'messageId' | 'message' | 'type'] : string}) => {
	if (typeof data !== 'object') return false;
	const keys: ('messageId' | 'message' | 'type')[] = Object.keys(data) as unknown as ('messageId' | 'message' | 'type')[];
	if (!keys.length) return false;
	const [ messageKey ] = keys;
	return EXIT_MESSAGES.some(message => {
		return message[messageKey] && message[messageKey] === data[messageKey];
	});
};
