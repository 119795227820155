import { RouterProvider } from 'react-router-dom';
import { createRouterData } from './browserRoutes';
import { profileSelectors } from '../redux/profile/selectors';
import { useAppSelector } from '../hooks/storeHooks';
import { storeReferer } from '../helpers/localStorageUtils';

const check_external_link = () => {
	const { search } = window.location;
	if (search && search.includes('utm_source', 0)){
		const filter = search.replace('?','');
		storeReferer(filter);
	}
};

const {
	selectAuthToken,
} = profileSelectors;

const AppRoutes = () => {
	const authToken = useAppSelector(selectAuthToken);
	check_external_link();
	const routerData = createRouterData(Boolean(authToken));
	return <RouterProvider router={routerData} />;
};


export default AppRoutes;
