import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ICard } from '../../components/withdraw/components/PaymentMethodFields/types';

import {
	IWithdrawBaseData, IWithdrawBaseError,
	IWithdrawFailure, IWithdrawMethod, IWithdrawPaymentMethod,
	IWithdrawSuccess, IWithdrawUI,
	WithdrawInitialState,
} from './types';
import { cloneDeep } from 'lodash';
import { RootState } from '../store';


const initialState: WithdrawInitialState = {
	withdrawMethods      : {},
	tronLinkFee          : '',
	tronLinkWithdrawError: false,
	withdraw_method      : null,
	baseData             : {
		amount: '',
	},
	baseError: {},
	failure  : {
		showError: false,
		errorMsg : '',
		id       : null,
	},
	success: {
		showSuccess: false,
		successMsg : '',
		id         : null,
	},
	UI: {
		loading        : false,
		loadingFee     : false,
		listLoading    : false,
		favouriteAmount: 0,
	},
	cards        : {},
	activePayment: 0,
};

const slice = createSlice({
	name    : 'withdraw',
	initialState,
	reducers: {
		setWithdrawMethods(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawMethod>) {
			state.withdrawMethods = action.payload;
		},
		setWithdrawFailure(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawFailure>) {
			state.failure = action.payload;
		},
		setWithdrawSuccess(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawSuccess>) {
			state.success = action.payload;
		},
		selectWithdrawMethod(state: Draft<WithdrawInitialState>, action: PayloadAction<IWithdrawPaymentMethod>) {
			state.withdraw_method = action.payload;
		},
		setCards(state, action: PayloadAction<{ cards: ICard[]; paymentID: number }>) {
			state.cards = { ...state.cards, [action.payload.paymentID]: action.payload.cards };
		},
		setFeeTron(state, action: PayloadAction<string>) {
			state.tronLinkFee = action.payload;
		},
		resetFeeTron(state) {
			state.tronLinkFee = '';
		},
		errorFeeTronlinkStore(state, action: PayloadAction<boolean>) {
			state.tronLinkWithdrawError = action.payload;
		},
		withdrawUiRefresh(state, action: PayloadAction<Partial<IWithdrawUI>>) {
			state.UI = Object.assign({}, state.UI, action.payload);
		},
		baseDataRefresh(state, action: PayloadAction<Partial<IWithdrawBaseData>>) {
			state.baseData = Object.assign({}, state.baseData, action.payload);
		},
		baseErrorRefresh(state, action: PayloadAction<Partial<Partial<IWithdrawBaseError>>>) {
			state.baseError = Object.assign({}, state.baseError, action.payload);
		},
		resetBase(state){
			state.baseData = cloneDeep(initialState.baseData);
			state.baseError = cloneDeep(initialState.baseError);
		},
		selectActivePayment: (state: Draft<WithdrawInitialState>, action: PayloadAction<number>) => {
			state.activePayment = action.payload;
		},
		resetSlice(){
			return   cloneDeep(initialState);
		},
	},
});

export const withdrawReducers ={
	...slice.actions,
};

export const selectWithdraw = (state: RootState) => state.Withdraw;

export default slice.reducer;
