import { FC, ReactNode, useState } from 'react';
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './tooltip.scss';

interface IProps {
    children        : ReactNode | ReactNode[];
    tooltipText     : string;
    info?           : string;
    dontShowIcon?   : boolean;
    minTextLength?  : number;
    customClassName?: string;
}

export const ToolTip: FC<IProps> = ({
	children,
	tooltipText = '',
	info,
	dontShowIcon,
	minTextLength,
	customClassName,
}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	const renderTooltip = () => {
		return (
			<span className={'tooltip_text'} style={{}}>
				{tooltipText}
			</span>
		);
	};
	const hover = (val: boolean) => {
		if (!minTextLength) {
			setShowTooltip(val);
			return;
		}
		if (tooltipText.length > minTextLength) {
			setShowTooltip(val);
		} else {
			setShowTooltip(false);
		}
	};

	return (
		<Tooltip
			open={showTooltip}
			arrow
			leaveDelay={100}
			placement="top"
			title={renderTooltip()}
			onOpen={() => hover(true)}
			onClose={() => hover(false)}
			className={customClassName}
		>
			<span
				onClick={() => hover(!showTooltip)}
			>
				{children}
				{!dontShowIcon ? !info ? <HelpOutlineIcon/> :
					<img width={'16px'} height={'14px'} src={'/resources/images/svg/info.svg'} alt={info}/>
					: null}
			</span>

		</Tooltip>
	);

//   return (
//     <Tooltip
//       placement={'top'}
//       title={tooltipText}
//       arrow
//       interactive
//     >
//       <span>
//         {children}
//       </span>
//     </Tooltip>
// )
};
