import { FormattedMessage } from 'react-intl';
import { modalReducers } from '../../redux/modals/slice';
import { useAppDispatch } from '../../hooks/storeHooks';

import './header-login.scss';

const lang = {
	login   : <FormattedMessage id="log_in"/>,
	register: <FormattedMessage id="register_now"/>,
};
const {
	setLoginUI,
	setRegisterUI,
} = modalReducers;
const HeaderLogin = () => {
	const dispatch = useAppDispatch();
	const onLogin = () => {
		dispatch(setLoginUI({ visible: true,loading: false,error: '',redirect: '' }));
	};
	const onRegister = () => {
		dispatch(setRegisterUI({ visible: true,loading: false,error: '' }));
	};
	return (
		<div className="sign_buttons">
			<button
				className="login_button"
				onClick={() => onLogin()}
				value="login"
			>
				{lang.login}
			</button>
			<button
				className="register_button"
				onClick={() => onRegister()}
				value="registration"
			>
				{lang.register}
			</button>
		</div>
	);
};

export default HeaderLogin;
