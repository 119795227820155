import { useState } from 'react';
import Switches from '../../../uiElements/Switches/Switches';
import { settingsReducers } from '../../../../redux/settings/slice';
import { useAppDispatch } from '../../../../hooks/storeHooks';
import './darkModeSwitch.scss';
import { SWITCH_TYPES } from '../../../uiElements/Switches/types';
import { toggleDarkLightMode } from '../../../../helpers/utils';

const DarkModeSwitch = () => {
	const getStorage = localStorage.getItem('themeMode');
	const darkLocale = getStorage === 'dark';
	const browserThemeMode: MediaQueryList = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
	const darkMode = getStorage ? darkLocale : browserThemeMode.matches;
	const [isThemMode, setIsThemMode] = useState(darkMode);
	const dispatch = useAppDispatch();
	const toggleMode = (checked: boolean) => {
		setIsThemMode(!isThemMode);
		toggleDarkLightMode(checked, settingsReducers.setSettingsUI, dispatch);
	};

	return (
		<Switches
			type={SWITCH_TYPES.SUN_AND_MOON}
			handleChange={toggleMode}
			value={isThemMode}
			customClass={'dark_mode_switch'}
		/>
	);
};

export default DarkModeSwitch;
