import moment, { Moment } from 'moment';
import { isNull, some } from 'lodash';
import { app_media_size } from '../config';
import { TRONLINK_APPSTORE_ID, TRONLINK_PLAY_MARKET_ID } from './constants';
import { SUBCATEGORY_TYPE } from '../constants/common';
import { restoreReferer } from './localStorageUtils';
import { documentResponseBody, documentResponseTitle } from './utility';
import { INormalizedNotificationData, INormalizeNotificationData, RefererData } from './types';
import { dateType } from '../components/history/filters/types';
import { IProvider } from '../redux/games/types';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ISettingsUI } from '../redux/settings/types';


const objectCloneDeep = <T>(data: T): T => {
	return JSON.parse(JSON.stringify(data));
};

const detectBrowserLanguage = () => {
	if (navigator) {
		if (navigator.languages) {
			if (navigator.languages[1]) {
				return navigator.languages[1];
			}
		}
	}
	return null;
};


const generateUniqueID = () => {
	const unique: string = new Date().getUTCMilliseconds() + '' + new Date().getTime();

	const chr4 = (): string => {
		return Math.random().toString(16).slice(-4);
	};
	return chr4() + '-' + unique + '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};

const onlyLettersAndNumbers  = (value: string) => {
	return value.replace(/[\W_]+/g, '');
};
const allowOnlyNumber        = (value: string) => {
	return value.replace(/\D/g, '');
};
const allowOnlyDotAndNumbers = (value: string) => {
	return value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2');
};


const isMobile = () => {
	let mobile = false; //initiate as false
	// device detection
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		mobile = true;
	}
	return mobile;
};


const detectMediaSize = () => {
	const wHeight      = window.innerHeight;
	const wWidth       = window.innerWidth;
	let sizeForCompare = 0;
	if (wHeight >= 540 && wWidth >= 540) {
		sizeForCompare = wHeight > wWidth ? wHeight : wWidth;
	}


	if (isMobile() && sizeForCompare <= +app_media_size.mobile) {
		return 'mobile';
	} else if (isMobile() && sizeForCompare <= +app_media_size.tablet) {
		return 'tablet';
	} else if (sizeForCompare <= app_media_size.medium[1] && sizeForCompare >= app_media_size.medium[0]) {
		return 'new_tablet';
	}

	return 'web';
};

const isTouchDevice = () => {
	return 'ontouchstart' in document.documentElement;
};

const widthScreen = () => {
	if (isTouchDevice()) {
		document.body.classList.add('touch_active');
	}
};


const diffDays = (firstDate: number | Date, secondDate: number | Date) => {
	const oneDay   = 24 * 60 * 60 * 1000;
	const startDate = new Date(firstDate).getTime();
	const endDate = new Date(secondDate).getTime();
	const diffDays = Math.round(Math.abs((
		startDate - endDate
	) / oneDay));
	return diffDays;
};

const sortCategories = <T extends {order_id: number}>(data: T[]) => {
	return data.sort((a, b) => {
		return a.order_id - b.order_id;
	});
};

const kFormatter = (balance: string, bonus: number = 0): string => {
	const num = parseFloat(balance || '0') + parseFloat(String(bonus));
	const applyK = Math.abs(num) > 9999;
	if (applyK) {
		const fixedNum = (Math.abs(num) / 1000).toFixed(1);
		return Math.sign(num) * Number(fixedNum) + 'k';
	}

	return (Math.sign(num) * Math.abs(num)).toFixed(2);
};

const stripTags = (str: string) => {
	return str.replace(/(<([^>]+)>)/ig, '');
};


const absolutePath = (path: string) => {
	const pattern = /^https?:\/\//i;
	return pattern.test(path);
};


const getRefererData = (): RefererData => {
	const data = restoreReferer();
	if (!data) {
		return {};
	}
	return data.split('&').reduce((obj: RefererData, str: string) => {
		const strParts: string[] = str.split('=');
		if (strParts[0] && strParts[1]) {
			obj[strParts[0].replace(/\s+/g, '')] = strParts[1].trim();
		}
		return obj;
	}, {});
};


const hashToArray    = (hash: string) => {
	if (!hash)
		return null;
	const pureHash = hash.replace('#', '');
	return pureHash.split(',');
};


const formatHash = (hash: string, newItem  = '') => {
	const pureHash = hash.replace('#', '');
	const hashArr  = pureHash.split(',');
	if (hashArr.includes(newItem.toString())) {
		return hashArr.filter((element) => {
			return element !== newItem.toString();
		}).join(',');
	} else {
		hashArr.push(newItem);
		return hashArr.join(',');
	}
};

const createQuery = (aliases: string[], pathname: string, isHome = false,  showAliases='', query='?') => {
	const filteredAliasesLength = aliases.length;
	if (filteredAliasesLength) {
		aliases.forEach(item => {
			query += `&sub-category[]=${item}`;
		});
		showAliases = `${query.replace('?&', '?')}`;
	}
	return isHome ? showAliases : `${pathname.replace(
		/\/(?!.*\/)/, '')}${showAliases}`;
};

const buildSubCategoryParams = (aliases: string[] = []) => {
	const subCategoryKey = 'sub-category[]';

	return aliases.reduce((acc, item) => {
		if (acc === '') {
			acc += `${subCategoryKey}=${item}`;
		} else {
			acc += `&${subCategoryKey}=${item}`;
		}

		return acc;
	}, '');
};

type UrlLabelPair<T> = {
	url  : T[keyof T];
	label: T[keyof T];
};

const formatCategoriesUrl = <T, K extends keyof T, L extends keyof T>(
	data: T[],
	urlField: K,
	labelField: L
): Record<string, UrlLabelPair<T>> => {
	const obj: Record<string, UrlLabelPair<T>> = {};
	for (let i = 0; i < data.length; i++) {
		const url = data[i][urlField];
		const label = data[i][labelField];
		if (typeof url === 'string' && typeof label === 'string') {
			obj[url] = { url, label };
		}
	}
	return obj;
};


const normalizeNotificationData = ({ review_date, type_id, id, status_id, url }: INormalizeNotificationData, userId: number) => {
	return <INormalizedNotificationData>{
		id,
		status_id,
		action : url,
		body   : documentResponseBody(status_id),
		created: review_date,
		img    : url,
		seen   : false,
		title  : documentResponseTitle(status_id),
		type   : type_id,
		user_id: userId,
		isDoc  : true,

	};
};

const getDataByCategoryType = <T, K>(providers: T[], customProviders: K[], type: SUBCATEGORY_TYPE) => {
	switch (type) {
		case SUBCATEGORY_TYPE.providers: {
			return providers ? providers : [];
		}
		case SUBCATEGORY_TYPE.customProviders: {
			return customProviders ? customProviders : [];
		}
		default: {
			return providers ? providers : [];
		}
	}
};

// filling object
function fill<T extends object, K extends object>(source: T, target:  K, withCreatingKeys: boolean = false) {

	const sourceKeys = Object.keys(source);
	const targetKeys = Object.keys(target);
	const result = objectCloneDeep(target);
	sourceKeys.forEach(key => {
		if (!targetKeys.includes(key) && !withCreatingKeys) {
			return;
		}
		// @ts-expect-error FIXME: fix
		result[key] = source[key];
	});
	return result;
}

function formatMomentToDate(date: Moment) {
	const day = date.format('DD');
	const month = date.format('MM');
	const year = date.format('YYYY');
	const hour = date.format('HH');
	const min = date.format('mm');

	return `${day}.${month}.${year} ${hour}:${min}`;
}
function checkTimeZoneValue(timeZone: string) {
	// Checking the correct timezone string for moment js
	const timeZoneOffset = timeZone.split(' ');
	return timeZoneOffset.length > 1 ? timeZoneOffset[1] : timeZoneOffset[0];
}

function formatDate(date: string | number | dateType, format: string, timeZone?: string) {
	if (timeZone) {
		const timeZoneCorrectValue = checkTimeZoneValue(timeZone);
		return moment(date).utcOffset(timeZoneCorrectValue).format(format);
	}

	return moment(date).format(format);
}


const isMobileDevice = () => {
	try {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
	} catch (e) {
		return false;
	}
};

/**
 *
 * @returns {{android: boolean, ios: boolean, windows: boolean}}  Object to
 *   know
 * device operating system: Works only for android, ios and windows mobile
 *   phones.
 */
const getDeviceOS = () => {
	const OS = {
		android: false,
		ios    : false,
		windows: false,
	};

	try {
		OS.android = /Android/i.test(navigator.userAgent);
		OS.ios     = /iPhone|iPad|iPod/i.test(navigator.userAgent);
		OS.windows = /Windows Phone/i.test(navigator.userAgent);
	} catch (e) {
		return OS;
	}
	return OS;
};

/**
 *
 * @param href is the Deeplink for Tron wallet mobile application
 * @param timeout is the timeout handler
 */
const openTronWalletOrMarket = (href: string, timeout = 1000 ) => {
	const OS = getDeviceOS();
	let exit = false;

	const timerID = setTimeout(() => {
		window.addEventListener('blur', () => {
			clearTimeout(timerID);
			exit = true;
		});
		if (document.hidden || document.visibilityState === 'hidden') {
			clearTimeout(timerID);
			return;
		}
		if (exit) {
			return;
		}

		clearTimeout(timerID);

		// open play market's or appstore's tronLink wallet page
		OS.android && (
			window.location.href = TRONLINK_PLAY_MARKET_ID
		);
		OS.ios && !document.hidden && (
			window.location.href = TRONLINK_APPSTORE_ID
		);
	}, 6*timeout);

	window.location.href = href;

	window.addEventListener('focus', () => {
		clearTimeout(timerID);
		exit = true;
	});
	if (document.hidden || document.visibilityState === 'hidden' ) {
		clearTimeout(timerID);
		exit = true;
		return;
	}

	return timerID;
};

function isDefinedNullOrZero(value: number | null) {
	return some([value === 0, isNull(value)]);
}

const hashToRoute = (hash: string, providers: IProvider[]) => {
	const pureHash = hash.replace('#', '');
	const hashArr = pureHash.split(',');
	const providersCopy = [...providers];
	return providersCopy.filter((element) => {
		const alias = element.alias ? 'alias' : 'provider_id';
		return hashArr.includes(element[alias]!.toString());
	});
};

const toggleDarkLightMode = (checked: boolean, callbackAction: ActionCreatorWithPayload<Partial<ISettingsUI>>, dispatch: Dispatch<AnyAction>) => {
	if (checked) {
		document.documentElement.setAttribute('data-theme', 'dark');
		dispatch(callbackAction(({ darkMode: checked })));
		localStorage.setItem('themeMode', 'dark');
	} else {
		document.documentElement.setAttribute('data-theme', 'light');
		dispatch(callbackAction(({ darkMode: checked })));
		localStorage.setItem('themeMode', 'light');
	}
};

const notificationsTypes = {
	generalNotifications : 1,
	pushNotifications    : 2,
	messagesNotifications: 3,
	bonusNotifications   : 4,
	smsNotifications     : 5,
	balanceNotifications : 6,
};

const BONUS_ITEMS_PER_PAGE = 6;

export {
	objectCloneDeep,
	detectBrowserLanguage,
	generateUniqueID,
	onlyLettersAndNumbers,
	allowOnlyNumber,
	detectMediaSize,
	allowOnlyDotAndNumbers,
	diffDays,
	sortCategories,
	kFormatter,
	stripTags,
	absolutePath,
	isTouchDevice,
	getRefererData,
	hashToArray,
	formatHash,
	formatCategoriesUrl,
	isMobileDevice,
	openTronWalletOrMarket,
	getDeviceOS,
	widthScreen,
	normalizeNotificationData,
	fill,
	getDataByCategoryType,
	createQuery,
	formatMomentToDate,
	buildSubCategoryParams,
	checkTimeZoneValue,
	formatDate,
	notificationsTypes,
	BONUS_ITEMS_PER_PAGE,
	isDefinedNullOrZero,
	hashToRoute,
	toggleDarkLightMode,
};
