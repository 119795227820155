import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { IBonusInitialState, IBonusAdapted, IBonusUI } from './types';

const initState: IBonusInitialState = {
	bonusData: [],
	UI       : {
		loading  : false,
		error    : '',
		showError: false,
		page     : 1,
	},
};
export const balance = createSlice({
	name        : 'balance',
	initialState: initState,
	reducers    : {
		setBonusData(state: Draft<IBonusInitialState>, action: PayloadAction<IBonusAdapted[]>) {
			state.bonusData = action.payload;
		},
		setUI(state: Draft<IBonusInitialState>, action: PayloadAction<Partial<IBonusUI>>) {
			const source = state.UI;
			const target = action.payload;
			const result = { ...source, ...target };
			state.UI = result;
		},
		resetBonusState() {
			return initState;
		},
	},
});


export const bonusReducers = {
	setBonusData   : balance.actions.setBonusData,
	setUI          : balance.actions.setUI,
	resetBonusState: balance.actions.resetBonusState,
};

export default balance.reducer;
