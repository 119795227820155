import { apiRequest } from '../../service/service';
import { IBonusParams } from './types';
import { PartialConfigs } from '../../service/types';

class BonusAPI {
	getBonuses = (params: IBonusParams) => {
		const url = '/v1/profile/casino/bonuses';
		const config: PartialConfigs<void, IBonusParams>= {
			method: 'get',
			url   : url,
			params: params,
			// params,
		};
		const result = apiRequest(config);
		return result;
	};
	getBonusWallets = () => {
		const url = '/v1/profile/casino/bonus/wallets';
		const config: PartialConfigs = {
			method: 'get',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};
	/* declineBonus = (params = {}) => {
      const url = `${API_URL}/v1/profile/casino/bonus/wallets`;
      const config = {
          method:'get',
          url:url,
          params,
      };
      const result  = apiRequest(config);
      return result;
    }; */

	claimDeclineBonus = (URL: string = '') => {
		const url = `/v1/profile/casino/bonus/${URL}`;
		const config: PartialConfigs = {
			method: 'put',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};

	claimBonus = (bonusID: number) => {
		const url = `/v1/profile/casino/bonus/${bonusID}/claim`;
		const config: PartialConfigs = {
			method: 'put',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};
	declineBonus = (bonusID: number) => {
		const url = `/v1/profile/casino/bonus/${bonusID}/decline`;
		const config: PartialConfigs = {
			method: 'put',
			url   : url,
		};
		const result = apiRequest(config);
		return result;
	};

}

export default BonusAPI;
