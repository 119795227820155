import React, { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { ClickAwayListener } from '@mui/material';
import GamesList from './games-list';
import { queryMinimumCount, queryRegExp } from '../../constants/common';
import gamesActions from '../../redux/games/actions';
import { gameReducers } from '../../redux/games/slice';
import { gamesSelectors } from '../../redux/games/selectors';
import { detectMediaSize } from '../../helpers/utils';
import SearchField from '../controls/search-field/search-field';
import { profileSelectors } from '../../redux/profile/selectors';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { menuReducers } from '../../redux/menu/slice';
import './search-panel.scss';


const {
	selectProfileData,
} = profileSelectors;
const {
	searchGamesAction,
} = gamesActions;
const {
	setSearchedGamesAction,
	setSearchTermAction,
} = gameReducers;
const {
	closeSidebar,
} = menuReducers;
const {
	selectSearchedGames,
} = gamesSelectors;

const SearchPanel: React.FC = () => {
	const user = useAppSelector(selectProfileData);
	const searchedGames = useAppSelector(selectSearchedGames);
	const [value, setVal] = useState('');
	const [searchTimeout, setTime] = useState<number>(0);
	const [focused,setFocused] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const isPathTournament = location.pathname.includes('tournament');
	const hasValue = value.length >= queryMinimumCount && queryRegExp.test(value);

	const handleChange = (value: string) => {
		clearTimeout(searchTimeout);
		setVal(value);

		if (value.length >= queryMinimumCount) {
			if (!focused) {
				setFocused(!focused);
			}
			const params = {
				name : value,
				page : 1,
				limit: 20,
			};

			const timeout = setTimeout(() => {
				if (queryRegExp.test(value)) {
					dispatch(searchGamesAction(params));
				}
			}, 500);
			setTime(Number(timeout));
		}
	};

	const resetSearch = () => {
		dispatch(setSearchTermAction(''));
		dispatch(setSearchedGamesAction([]));
		setVal('');
	};

	const showMore = () => {
		setFocused(false);
		navigate({
			pathname: 'search-result',
			search  : `?term=${value}`,
		});
	};

	const handleFocus = () => {
		const device = detectMediaSize();
		setFocused(true);
		if (device === 'new_tablet') {
			dispatch(closeSidebar());
		}
	};

	return (
		<Fragment>
			<ClickAwayListener onClickAway={() => setFocused(false)}>
				<div className={`search_block ${isPathTournament ? 'search_block_tournament' : ''}${focused && hasValue ? 'showed' : ''}`}>
					<SearchField value={value} placeholder="find_your_game" changeSearch={handleChange} resetSearch={resetSearch} onFocus={handleFocus}/>
					<div className="results_list">
						<div>
							<GamesList data={searchedGames || []} user={user} resetSearch={resetSearch}/>
						</div>
						{
							searchedGames && searchedGames.length > 10 ?
								<div className="more_link"><button onClick={showMore}><FormattedMessage id="show_more"/></button></div>
								: null
						}
					</div>
				</div>
			</ClickAwayListener>
		</Fragment>
	);
};

export default SearchPanel;
