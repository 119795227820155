import {
	ICategoriesParam,
	IGameGroupListParam,
	IGameInfoPayload,
	IGameListParam,
	IGameSearchPayload,
	IGameStartPayload,
	IProvidersActionParams,
	ISubCategoriesParam,
} from './types';

const actions = {
	GET_GAMES_LIST          : 'GET_GAMES_LIST',
	SET_GAMES_LIST          : 'SET_GAMES_LIST',
	SEARCH_GAMES            : 'SEARCH_GAMES',
	SET_SEARCHED_GAMES      : 'SET_SEARCHED_GAMES',
	GET_CATEGORIES          : 'GET_CATEGORIES',
	GET_SUB_CATEGORIES      : 'GET_SUB_CATEGORIES',
	SET_SUB_CATEGORIES      : 'SET_SUB_CATEGORIES',
	SET_CATEGORIES          : 'SET_CATEGORIES',
	GET_PROVIDERS           : 'GET_PROVIDERS',
	SET_PROVIDERS           : 'SET_PROVIDERS',
	GET_GAMES_BY_CATEGORIES : 'GET_GAMES_BY_CATEGORIES',
	SET_GAMES_BY_CATEGORIES : 'SET_GAMES_BY_CATEGORIES',
	SELECT_PROVIDER         : 'SELECT_PROVIDER',
	SELECT_CATEGORY         : 'SELECT_CATEGORY',
	GET_GAMES_BY_PARAMS     : 'GET_GAMES_BY_PARAMS',
	SEARCH_HOME_GAMES       : 'SEARCH_HOME_GAMES',
	SET_HOME_SEARCH_GAMES   : 'SET_HOME_SEARCH_GAMES',
	SET_GAME_NAME           : 'SET_GAME_NAME',
	SET_SEARCHED_TERM       : 'SET_SEARCHED_TERM',
	SET_MOBILE_SEARCHED_TERM: 'SET_MOBILE_SEARCHED_TERM',
	RESET_FILTERS           : 'RESET_FILTERS',
	START_GAME              : 'START_GAME',
	INFO_GAME               : 'INFO_GAME',
	RESET_GAMES_GROUP       : 'RESET_GAMES_GROUP',
	SET_GAME                : 'SET_GAME',
	GET_SIMILAR             : 'GET_SIMILAR',
	SET_SIMILAR             : 'SET_SIMILAR',
	GET_GAMES_GROUPS        : 'GET_GAMES_GROUPS',
	SET_GAMES_GROUPS        : 'SET_GAMES_GROUPS',
	SET_SHOW_MORE           : 'SET_SHOW_MORE',
	SET_PAGE_NUMBER         : 'SET_PAGE_NUMBER',
	SET_UNCLEARED_GAMES     : 'SET_UNCLEARED_GAMES',
	UI_REFRESH              : 'UI_REFRESH',

	searchGamesAction: (data: IGameSearchPayload) => {
		return {
			type: actions.SEARCH_GAMES,
			data,
		};
	},

	getCategoriesAction: (data?: ICategoriesParam) => {
		return {
			type: actions.GET_CATEGORIES,
			data,
		};
	},

	// setCategoriesAction: data => {
	// 	return {
	// 		type: actions.SET_CATEGORIES,
	// 		data,
	// 	};
	// },

	getSubCategoriesAction: (data: ISubCategoriesParam) => {
		return {
			type: actions.GET_SUB_CATEGORIES,
			data,
		};
	},

	getProvidersAction: (data: IProvidersActionParams) => {
		return {
			type: actions.GET_PROVIDERS,
			data,
		};
	},

	getGamesListAction: (data: IGameListParam) => {
		return {
			type: actions.GET_GAMES_LIST,
			data,
		};
	},

	startGameAction: (data: IGameStartPayload) => {
		return {
			type: actions.START_GAME,
			data,
		};
	},

	getInfoGame: (data: IGameInfoPayload) => {
		return {
			type: actions.INFO_GAME,
			data,
		};
	},

	searchHomeGamesAction: (data: IGameSearchPayload) => {
		return {
			type: actions.SEARCH_HOME_GAMES,
			data,
		};
	},

	getGamesGroupsAction: (data: IGameGroupListParam) => {
		return {
			type: actions.GET_GAMES_GROUPS,
			data,
		};
	},

	selectCategory: (data: number) => {
		return {
			type: actions.SELECT_CATEGORY,
			data,
		};
	},
};

export default actions;
