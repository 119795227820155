import { ILanguage, IUi } from '../types';
import { ICategory, IGame, IProvider, ICustomProvider } from '../games/types';
import { AxiosHeaders } from 'axios';
import { StringOrNull } from '../../types/types';
import { IBanner } from '../banners/types';
import { CHANNELS } from './utils';
import { CurrencyList, PickOne } from '../../components/deposit/types';

type TStringOrNull = StringOrNull;

export interface IInitSettings {
  countries       : ICountry[];
  currencies      : ICurrency[];
  current_language: Partial<ILanguage> | undefined | null;
  layout          : TLayout;
  loader          : { section: string; data: TParamsType };
  scripts         : ScriptItem[];
  state           : { section: string; data: TParamsType };
  initialSettings : Partial<ISettings> ;
  languages       : ILanguage[];
  SEO             : ISEO;
  appMediaSize    : string;
  UI              : Partial<ISettingsUI>;
  cookieScripts   : ScriptItem[];
  slider          : IBanner[];
  promotions?     : TParamsType;
  games?          : TParamsType;
  selectedCategory: number;
  selectedSubIDs  : Array<number>;
  channelID       : CHANNELS;
  sidebar_blog    : Array<unknown>;
}

export interface ISEO {
  pageName      : TStringOrNull;
  seoTitle      : TStringOrNull;
  seoDescription: TStringOrNull;
  seoKeywords   : TStringOrNull;
  ogTitle       : TStringOrNull;
  ogDescription : TStringOrNull;
  ogImageURL    : TStringOrNull;
}

export interface ISEOResponse {
  alias          : string;
  seo_title      : TStringOrNull;
  seo_description: TStringOrNull;
  seo_keywords   : TStringOrNull;
  og_title       : TStringOrNull;
  og_description : TStringOrNull;
  og_image       : TStringOrNull;
}


export type TAdaptList = IGame[] | ICategory[] | ICustomProvider[] | IProvider[];
export interface IAdapt extends ISEOResponse{
  name: string;
}

export type TLayout = 'error' | 'game' | 'promotion' | '';
export type TBannerSections = 'promotions' | 'games';

export interface ISettingsUI extends IUi {
  data?       : boolean;
  loading     : boolean;
  isOnChanged?: boolean;
  appStarted  : boolean;
  darkMode?   : boolean;
  appReady?   : boolean;
}
export interface ICookieScript {
  [key: string]: string;
}

export interface ICountry {
  label: string;
  value: string;
  code : string;
}


export interface DateTimeResult {
  daysFormat : string;
  timeFormats: string;
}


export interface IGetValueCookieResult {
  type : string;
  value: number[];
}

export interface ScriptPosition {
  head: number;
  body: number;
}

export interface ScriptItem {
  id         : number;
  position_id: number;
  script     : string;
  type       : number;
  website_id : number;

}

export interface IActions {
  type : string;
  data?: IActionsData;
}

export interface ICurrency {
  id         : number;
  website_id : number;
  currency_id: number;
  order_id   : number;
  description: string;
  code       : PickOne<CurrencyList>;
}

export interface ICurrencyPayload {
    data   : ICurrency[];
    section: string;
}

export interface ScriptSeparationResult {
  cookieScripts: ScriptItem[];
  scriptsNone  : ScriptItem[];
}


export interface ApiRequestConfig<T,P> {
  method        : string;
  url           : string;
  lang_id?      : number;
  params       ?: P;
  headers       : Partial<AxiosHeaders>;
  hideError?    : boolean;
  data          : T;
}

// ApiResponse type
export interface ApiResponse<T> {
  data  : T;
  status: number;
}


export interface ISettingsAction {
  type: string;
  data: IGetSettingsPayload;
}

type TParamsType = {
  group_name        : string;
  request_from_front: boolean;
  website_id        : number;
  channel_id        : number;
};


export interface IGetSettingsPayload {
      locale       : string;
      langID       : number;
      withNavigate?: boolean;
}


export type TParams = Record<string, TParamsValue>;
export interface IParams extends Record<string | number, TParamsValue>{

}

export  type TParamsValue = string | number | boolean | null  | number[] | string[];


type TLanguage = {
  lang_id: number;
  code   : string;
};


export interface ISettings {

  date_format      : string;
  customer_age     : number;
  suspend_all      : boolean;
  suspend_prematch : boolean;
  suspend_inplay   : boolean;
  inplay_delay     : number;
  prematch_delay   : number;
  lang_id          : number;
  game_load_type   : number;
  sub_category_type: number;
  website_languages: TLanguage[];
  require_cookie   : boolean;
  seo_title        : string;
  seo_description  : string;
  seo_keywords     : string;
  og_title         : string;
  og_description   : string;
  og_image         : string;
  national_id      : false;
  timeFormats?     : string;
  daysFormat?      : string;
  analytics_id     : string;
  enable_ocr       : boolean;
  resendEmailTime  : number;
}

export interface IStoreData {
  current_language: Partial<ILanguage> | null | undefined;
}

export interface IInitialSettingsPayload {
    lengId: number;
}

export enum BANNER_GROUPS {
  lumos = 'lumos',
  footerSocial = 'footer-socials',
  footerProviders = 'footer-providers',
  footerApps = 'footer-apps',
  footerCompanies = 'footer-companies',
  footerPayments = 'footer-payments',
  sidebarBlog = 'sidebar-blog',
}

export enum BANNER_SECTIONS {
  slider = 'slider',
  providers = 'providers',
  verify = 'verify',
  footerCompanies = 'footerCompanies',
  payments = 'payments',
  socials = 'socials',
  sidebarBlog = 'sidebar_blog',
}

export enum GAME_LOAD_TYPE {
  INFINITE_SCROLL = 1,
  SHOW_MORE       = 2,
}

export interface IBanners {
  id            : number;
  image_url     : string;
  link          : string;
  link_text     : string;
  name          : string;
  order_id      : number;
  script_code   : string;
  sub_title     : string;
  title         : string;
  description   : string;
  view_count    : number;
  max_view_count: number;
}

export interface IActionsData {
  section: TBannerSections;
  data   : TParamsType;
}
export interface IActionsBanner extends IActions {
  type: string;
  data: IActionsData;
}

export interface IBannersCallData {
  type: string;
  data: IBannersCallDataData;
}

export interface IBannersCallDataData {
  params : TParamsType;
  section: BANNER_SECTIONS;
}
