import { IHistoryFilters, ISetFiltersPayload } from './types';

const actions = {
	GET_CASINO_HISTORY            : 'GET_CASINO_HISTORY',
	GET_DEPOSIT_HISTORY           : 'GET_DEPOSIT_HISTORY',
	GET_WITHDRAWALS_HISTORY       : 'GET_WITHDRAWALS_HISTORY',
	GET_CASINO_BONUSES            : 'GET_CASINO_BONUSES',
	GET_CASINO_SPINS              : 'GET_CASINO_SPINS',
	GET_BALANCE_ADJUSTMENT_HISTORY: 'GET_BALANCE_ADJUSTMENT_HISTORY',
	FINISH_TRANSACTION            : 'FINISH_TRANSACTION',
	SET_FILTERS                   : 'SET_FILTERS',

	getCasinoHistory: (filters: IHistoryFilters) => {
		return {
			type: actions.GET_CASINO_HISTORY,
			data: filters,
		};
	},

	getCasinoBonuses: (data: IHistoryFilters) => {
		return {
			type: actions.GET_CASINO_BONUSES,
			data,
		};
	},

	getCasinoSpins: (data: IHistoryFilters) => {
		return {
			type: actions.GET_CASINO_SPINS,
			data,
		};
	},

	getDepositHistory: (data: IHistoryFilters) => {
		return {
			type: actions.GET_DEPOSIT_HISTORY,
			data,
		};
	},

	getWithdrawalsHistory: (data: IHistoryFilters) => {
		return {
			type: actions.GET_WITHDRAWALS_HISTORY,
			data,
		};
	},

	getBalanceAdjustmentHistory: (data: IHistoryFilters) => {
		return {
			type: actions.GET_BALANCE_ADJUSTMENT_HISTORY,
			data,
		};
	},
	setFilters: (data: ISetFiltersPayload) => {
		return {
			type: actions.SET_FILTERS,
			data,
		};
	},
	// ts-expect-error FIXME: Parameter 'data', the function is not used anywhere
	finishTransaction: (data: unknown) => {
		return {
			type: actions.FINISH_TRANSACTION,
			data,
		};
	},
};

export default actions;
