import { FormattedMessage } from 'react-intl';
import './no-data.scss';

const NoData = ({ className='no_data_block' }) => {
	return (
		<div className={className}>
			<img src="/resources/images/svg/no_data.svg" className="no_data_icon" alt="" title=""/>
			<div className="no_data_title"><FormattedMessage id="no_data_title"/></div>
			<div className="no_data_info"><FormattedMessage id="no_data_info"/></div>
		</div>
	);
};

export default NoData;
