import React, { useEffect } from 'react';
import Listing from '../../controls/listing';
import DropDownList from '../../controls/dropdown-list';
import SidebarBlog from '../sitebar-blog';
import SidebarFooter from '../sidebar-footer';
import menuActions from '../../../redux/menu/actions';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { menuSelectors } from '../../../redux/menu/selectors';
import { menuReducers } from '../../../redux/menu/slice';
import { defaultLanguage } from '../../../config';
// import { ILeftCategories } from './types';
import { selectSettingsCurrentLanguage } from '../../../redux/settings/selectors';

const {
	selectBottomMenu,
	selectBottomLeftMenu,
	selectSidebar,
	selectMainMenu,
} = menuSelectors;
const {
	toggleSidebar,
} = menuReducers;
const {
	getBottomMenuAction,
	getLeftMenuAction,
} = menuActions;
const SidebarBottom: React.FC = () => {
	const mainMenu = useAppSelector(selectMainMenu);
	const bottomMenu = useAppSelector(selectBottomMenu);
	const bottomLeftMenu = useAppSelector(selectBottomLeftMenu);
	const sidebar = useAppSelector(selectSidebar);
	// const categories = useAppSelector(state => state.Games.get('categories'));
	const currentLanguage = useAppSelector(selectSettingsCurrentLanguage);
	const dispatch = useAppDispatch();

	// const leftMenuCategories: ILeftCategories[] = useMemo(() => {
	// 	const leftCategories = categories ? categories.filter((c: { left_menu: boolean }) => c.left_menu) : [];
	// 	return leftCategories.map((item: { id: number; name: string; alias: string }) =>({
	// 		id   : item.id,
	// 		name : item.name,
	// 		icon : item.alias,
	// 		url  : `/${item.alias}/`,
	// 		alias: item.alias,
	// 	}));
	// }, [categories]);

	const onSidebarToggle = () => {
		dispatch(toggleSidebar());
	};

	useEffect(() => {
		dispatch(getBottomMenuAction());
		dispatch(getLeftMenuAction());
	}, [dispatch]);

	if (!bottomMenu) {
		return false;
	}

	if (!currentLanguage) {
		return  <div />;
	}

	return (
		<div className="sidebar_bottom">
			<Desktop>
				<div className="menues_container">
					<div className="menues_list">
						{mainMenu.length > 0 &&
							<div className="main_menu">
								<Listing data={mainMenu} langCode={currentLanguage?.code || defaultLanguage.code} />
							</div>
						}
						<div className={`second_menu  ${!sidebar ? 'hide' : ''}`}>
							{bottomLeftMenu.columns && bottomLeftMenu.columns.length > 0 && (
								<DropDownList
									data={bottomLeftMenu}
									sidebar={sidebar}
									callbackAction={onSidebarToggle}
									langCode={currentLanguage?.code || ''}
									translated
								/>
							)}
						</div>
					</div>
				</div>
			</Desktop>
			<NotDesktop>
				<div className="main_menu">
					<Listing langCode={currentLanguage?.code || defaultLanguage.code} data={mainMenu} />
				</div>
				<div className="second_menu">
					<DropDownList
						data={bottomLeftMenu}
						sidebar={sidebar}
						callbackAction={onSidebarToggle}
						langCode={currentLanguage?.code || ''}
						translated
					/>
				</div>
			</NotDesktop>
			<SidebarBlog />
			<SidebarFooter />
		</div>
	);
};

export default SidebarBottom;
