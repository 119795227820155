import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../hooks/storeHooks';
import { IListingProps } from './types';
import { gameReducers } from '../../../redux/games/slice';
import './listing.scss';

const Listing: React.FC<IListingProps> = (props) => {

	const dispatch = useAppDispatch();
	const location = useLocation();
	const { data, button, langCode } = props;

	const pathname =  location.pathname.split('/');

	const receiveGames =()=>{
		dispatch(gameReducers.setPageNumberAction(1));
	};

	const ShowButton = button ? <button  className="play_btn"><FormattedMessage id={button} /></button> : null;

	const Result = data.map((item, idx) => {
		const label = item.name ? <FormattedMessage id={item.name} /> : <FormattedMessage id={item.alias} />;
		const icon = item.icon ? `icon_${item.icon}` : '';
		return (
			<li key={`list-item-${idx}`}>
				{item.thumb_url ? <img src={`${item.thumb_url}`} alt="" title="" /> : null}
				{
					item.url ? <Link
						onClick={()=>{receiveGames();}}
						className={`menu_item ${icon} 
                                ${pathname[1] === item.alias? 'current' : '' }  `}
						to={`/${langCode}${item.url}`}>
						{label}
					</Link> : <div className={`menu_item ${icon} `}>{label}</div>
				}

				{ShowButton}
			</li>
		);
	});


	return (
		<ul>
			{Result}
		</ul>

	);
};

export default Listing;
