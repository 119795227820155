import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPromotions, IPromotionsInitState } from './types';

const initState: IPromotionsInitState = {
	promotions    : [],
	promotionInner: {
		id       : 0,
		alias    : '',
		lang_id  : 0,
		promotion: {
			name                      : '',
			games                     : [],
			title                     : '',
			og_image                  : '',
			og_title                  : '',
			subtitle                  : '',
			seo_title                 : '',
			description               : '',
			header_image              : '',
			seo_keywords              : '',
			og_description            : '',
			seo_description           : '',
			thumbnail_image           : '',
			call_to_action_image      : '',
			call_to_action_title      : '',
			call_to_action_subtitle   : '',
			call_to_action_button_link: '',
			call_to_action_button_text: '',
		},
	},
};
const promotions = createSlice({
	name        : 'promotions',
	initialState: initState,
	reducers    : {
		setPromotions(state: Draft<IPromotionsInitState>, action: PayloadAction<IPromotions[] | null>) {
			state.promotions = action.payload;
		},
		setPromotionInner(state: Draft<IPromotionsInitState>, action: PayloadAction<IPromotions>) {
			state.promotionInner = action.payload;
		},
	},
});


export const promotionsReducers = {
	setPromotions    : promotions.actions.setPromotions,
	setPromotionInner: promotions.actions.setPromotionInner,
};

export default promotions.reducer;
