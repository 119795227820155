import { FC } from 'react';
import { useAppSelector } from '../../../hooks/storeHooks';
import { getSettingsUI } from '../../../redux/settings/selectors';
import { ILoaderProps } from './types';
import './loader.scss';

const Loader: FC<ILoaderProps> = ({ notFixed }) => {
	const { darkMode } = useAppSelector(getSettingsUI);

	const logo = darkMode ? '/resources/images/svg/lumos_logo-dark.svg' : '/resources/images/svg/main_logo.svg';
	return (
		<div className={`page_loader ${notFixed ? 'notfixed' : ''}`}>
			<div className="logo_block">
				<img src={logo} alt="" title=""/>
			</div>
		</div>
	);
};

export default Loader;
