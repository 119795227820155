import { RootState } from '../store';

export const menuSelectors = {
	selectBottomMenu       : (state: RootState) => state.Menu.bottomMenu,
	selectBottomLeftMenu   : (state: RootState) => state.Menu.leftMenu,
	selectBottomMenuContent: (state: RootState) => state.Menu.bottomMenuContent,
	selectMainMenu         : (state: RootState) => state.Menu.mainMenu,
	selectSidebar          : (state: RootState) => state.Menu.sidebar,
	selectSitemap          : (state: RootState) => state.Menu.sitemap,
};
