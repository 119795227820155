import { apiRequest } from '../../service/service';
import { IGameInfoPayload, IGameParams, IGameStartPayload, IProvidersParams, ISimilarGames } from './types';
import { PartialConfigs } from '../../service/types';

export default class Games {

	getCategoriesList = () => {
		const url = '/v1/casinoCategories';
		const config = {
			method: 'get',
			url   : url,
		};

		return apiRequest(config);
	};

	getSubCategoriesList = (params: IProvidersParams) => {
		const url = '/v1/casino/subCategoriesByCategories';
		const config: PartialConfigs<void, IProvidersParams> = {
			method: 'get',
			url,
			params,
		};

		return apiRequest(config);
	};

	getProvidersList = (params: IProvidersParams) => {
		const url = '/v1/casino/providersByCategories';
		const config = {
			method: 'get',
			url   : url,
			params,
		};

		return apiRequest(config);
	};

	getSimilarGames = ({ category, ...rest }: ISimilarGames) => {
		const url = `/v1/casinoCategory/${category}/similar`;
		const config = {
			method: 'get',
			url   : url,
			params: {
				...rest,
			},
		};

		return apiRequest(config);
	};

	getGamesList = (params: IGameParams) => {
		const url = '/v1/casinoGame/list';
		const config: PartialConfigs<void,IGameParams> = {
			method: 'get',
			url   : url,
			params: {
				...params,
			},
		};

		return apiRequest(config);
	};

	startGame = (data: IGameStartPayload) => {
		const url = '/v1/casino/startGame';
		const config: PartialConfigs<IGameStartPayload> = {
			method: 'post',
			url   : url,
			data,
		};

		return apiRequest(config);
	};

	infoGame = (params: IGameInfoPayload) => {
		const url = '/v1/gameInfo';
		const config: PartialConfigs<void,IGameInfoPayload> = {
			method: 'get',
			url   : url,
			params,
		};

		return apiRequest(config);
	};
}
