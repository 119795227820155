import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Desktop } from '../../helpers/devices';
import SidebarUserActions from '../sidebar/sidebar-user-actions';
import { kFormatter } from '../../helpers/utils';
import { currencyList } from '../../config';
import { ROUTE_PATH } from '../../routes/utils';
import { profileSelectors } from '../../redux/profile/selectors';
import { useAppSelector } from '../../hooks/storeHooks';
import { useParams } from 'react-router';
import { getSettingsUI } from '../../redux/settings/selectors';
import './header-profile.scss';


const {
	selectProfileData,
} = profileSelectors;
const HeaderProfile = () => {
	const profileData = useAppSelector(selectProfileData);
	const location = useLocation();
	const { locale } = useParams();
	const depositColor = location.pathname;
	const { darkMode } = useAppSelector(getSettingsUI);

	const backgroundImage = darkMode ? 'url(/resources/images/bg_profile_left_dark.png)' : 'url(/resources/images/reg_bonus_bg.png)';
	if (!profileData) {
		return false;
	}

	const { first_name, last_name, username, email } = profileData;

	const name = !first_name && !last_name && username ? username :
		!first_name && !last_name && email ? email :
			`${first_name} ${last_name}`;

	return (
		<div className="profile_block">
			<div className="user_block" style={{ backgroundImage }}>
				<Link to={ROUTE_PATH.profile} className="user_info">
					<span className="user_image">
						{
							profileData.avatar ? (
								<img src = {`${profileData.avatar}`} alt = '' title = ''/>
							) : <img src="/resources/images/rabbit.jpg" alt="" title=""/>
						}
					</span>
					<span className="name_balance">
						<span className="user_name">{name}</span>
						{/*@ts-expect-error FIXME: currency list should be properly typed*/}
						<span className="user_balance">{currencyList[profileData.currency_code] || profileData.currency_code}{kFormatter(String(profileData.balance || ''),profileData.bonus_balance)}</span>
					</span>
				</Link>
				<Link className="main_btn icon_plus" to={`/${locale}/${ROUTE_PATH.deposit}/`}/>
			</div>
			<SidebarUserActions/>
			<Desktop>
				<Link className="main_btn icon_deposit"
					style={ depositColor.includes(`${ROUTE_PATH.root}${ROUTE_PATH.deposit}`) ? { backgroundColor: '#0069F8',color: '#ffffff' } : undefined }
					to={`/${locale}/${ROUTE_PATH.deposit}/`}>
					<span className='btn_text'><FormattedMessage id="deposit"/></span>
				</Link>
			</Desktop>
			<Desktop>
				<Link className="tournament_btn main_btn icon_cup"
					style={ depositColor.includes(`${ROUTE_PATH.root}${ROUTE_PATH.tournament}`) ? { backgroundColor: '#0069F8',color: '#ffffff' }  : undefined }
					to={ROUTE_PATH.tournament}>
					<span className='btn_text'><FormattedMessage id="tournaments"/></span>
				</Link>
			</Desktop>
			<Desktop>
				<Link
					className={`sports_book_btn main_btn icon_bet ${depositColor.includes(`${ROUTE_PATH.root}${ROUTE_PATH.sports}`) ? 'active' : ''}`}
					to={ROUTE_PATH.sports}>
					<span className='btn_text'><FormattedMessage id="sports"/></span>
				</Link>
			</Desktop>
		</div>
	);
};

export default HeaderProfile;
