import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import api from './api';
import promotionActions from './actions';
import { adaptSeo } from '../settings/utils';
// import settingsActions from '../settings/actions';
import { logger } from '../../helpers/debugLogger';
import { AxiosApiResponse } from '../../service/types';
import { IPromotions } from './types';
import { RootState } from '../store';
import { promotionsReducers } from './slice';
import { ISagaActionType } from '../types';
import { ISEOResponse } from '../settings/types';
// import { settingsReducers } from '../settings/slice';

const API = new api();

const getLocale = ({ Settings }: RootState) => {
	return Settings.current_language?.id;
};

function* getPromotions() {
	yield takeEvery(promotionActions.GET_PROMOTIONS, function* () {
		try {
			const params: { lang_id: number } = {
				lang_id: yield select(getLocale),
			};
			const response: AxiosApiResponse<IPromotions[]> = yield call(API.getPromotions, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(promotionsReducers.setPromotions(data));
			}
		} catch (e) {
			logger.log(e);
		}
	});

}

function* getPromotionInner() {
	yield takeEvery(promotionActions.GET_PROMOTION_INNER, function* (action: ISagaActionType<string>) {
		try {
			const params: { lang_id: number; alias: string } = {
				lang_id: yield select(getLocale),
				alias  : action.data,
			};
			const response: AxiosApiResponse<IPromotions> = yield call(API.getPromotionInner, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(promotionsReducers.setPromotionInner(data));
				const { promotion } = data;
				const seoRespons:ISEOResponse = {
					alias          : data.alias,
					og_description : promotion.og_description,
					og_image       : promotion.og_image,
					og_title       : promotion.og_title,
					seo_description: promotion.seo_description,
					seo_keywords   : promotion.seo_keywords,
					seo_title      : promotion.seo_title,
				};
				//@ts-expect-error   FIXME: change adapt seo function arg types
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const dataSeo = adaptSeo(seoRespons);
				// yield put(settingsReducers.setBannersAction({ section: 'SEO', data: dataSeo })); TODO FIXME
			}
		} catch (e) {
			logger.log(e);
		}
	});

}


function* promotionSaga() {
	yield all([
		fork(getPromotions),
		fork(getPromotionInner),
	]);
}

export default promotionSaga;
