import { fork, all, put, select, call } from 'redux-saga/effects';
import appSocket from '../socket';
import { clearData, restoreTronTransactionLSKey } from '../../helpers/localStorageUtils';
import {
	COMMUNICATION_CODES,
	TRON_SOCKET_STATUS,
} from '../../redux/communicationCodes';
import { modalReducers } from '../../redux/modals/slice';
import actions from './actions';
import { logger } from '../../helpers/debugLogger';
import { depositReducers } from '../../redux/deposit/slice';
import { STORAGE_KEYS } from '../../helpers/constants';
import { socketReducers } from '../../redux/socket/slice';
import { ISocketGetResponse, ISocketSelectStore, ITronGetDepositStatusResponse, ITronSocketResponse } from './types';
import { RootState } from '../../redux/store';
import { ISagaActionType, ISocketSagaPayload } from '../../redux/types';
import { ITempTronAccount } from '../../redux/deposit/types';

const socketEmit = <T, R>(data: T) => {
	return new Promise((resolve, reject) => {
		if (!appSocket.socket) {
			logger.log('socket is not defined');
			reject(new Error('Socket is not defined'));
			return;
		}
		appSocket.socket.emit('get', data, (res: ISocketGetResponse<R>) => {
			switch (res.code) {
				case COMMUNICATION_CODES.SOCKET_DEACTIVATE_ACCOUNT: {
					if (res.isSuccess) {
						window.location.reload();
					}
					break;
				}

				case COMMUNICATION_CODES.SOCKET_GET_ACTION_ID_DEPOSIT : {
					if (res.isSuccess && res.body.actionData) {
						resolve(res.body.actionData.tronlink_address);
					}
					break;
				}
				case COMMUNICATION_CODES.SOCKET_GET_DEPOSIT_STATUS : {
					// In Tron application we have 2 ways to interact: Confirm and cancel.
					// At first FE request data.
					// 1. Case Cancel: response received: means cancel button clicked.
					// 2. Case Confirm:  BE doesn't send data,
					//    so it means if we have actionID (TronTransactionLSKey) user has confirmed the transaction
					if (res.isSuccess && res.body.actionData) {
						resolve({
							cancelled: res.body.actionData.status_id === TRON_SOCKET_STATUS.cancelled,
						} );
					} else {
						const trxID = restoreTronTransactionLSKey();
						resolve({
							confirmed: !!trxID && !res.isSuccess,
						});
					}
					break;
				}

				default: {
					logger.log('Default case');
				}
			}

			// reject(new Error('Unexpected response from server'));
		});
	});
};


const selectState = ({ Profile, Deposit }: RootState): ISocketSelectStore => {
	const actionID = Profile.tronlink.signParams.actionId;
	const actionIDDeposit = Profile.tronlink.params.actionId;
	const tempTronAccount = Deposit.tempTronAccount;
	return {
		actionID,
		actionIDDeposit,
		tempTronAccount,
	};
};

function* socketAction(action: ISagaActionType<ISocketSagaPayload>) {
	const { tempTronAccount } = yield select(selectState);

	switch (action.type) {
		case actions.SOCKET_CONNECTION_STATUS_SET: {
			const { status } = action.data;
			yield put(socketReducers.setSocketStatus(status));
			break;
		}

		case actions.SOCKET_CONNECTION_SID: {
			const { sid } = action.data;
			yield put(socketReducers.setSocketSID(sid));
			break;
		}

		case actions.EMIT_ACTION_ID_DEACTIVATE: {
			const { actionID }: ISocketSelectStore = yield select(selectState);

			const data = {
				code: COMMUNICATION_CODES.SOCKET_DEACTIVATE_ACCOUNT,
				body: { action_id: actionID  },
			};

			try {
				// @ts-expect-error FIXME: check socket emitter for tronlink
				const res: ITempTronAccount = yield call(socketEmit, data, tempTronAccount);
				tempTronAccount.account = res;
				yield put(depositReducers.tempAccountRefresh({ ...tempTronAccount }));

			}
			catch (e) {
				logger.log('e :', e);
			}

			break;

		}

		case actions.EMIT_ACTION_ID_DEPOSIT: {
			const {  actionIDDeposit } = yield select(selectState);

			const data = {
				code: COMMUNICATION_CODES.SOCKET_GET_ACTION_ID_DEPOSIT,
				body: { action_id: actionIDDeposit },
			};

			try {
				// @ts-expect-error FIXME: check socket emitter for tronlink
				const res: ITronSocketResponse = yield call(socketEmit, data, tempTronAccount);
				tempTronAccount.account = res;
				yield put(depositReducers.tempAccountRefresh({ ...tempTronAccount }));

			}
			catch (e) {
				logger.log('e :', e);
			}

			break;

		}


		case actions.EMIT_ACTION_ID_DEPOSIT_STATUS: {
			const trxID = restoreTronTransactionLSKey();
			const data = {
				code: COMMUNICATION_CODES.SOCKET_GET_DEPOSIT_STATUS,
				body: { action_id: trxID },
			};
			try {
				const res: ITronGetDepositStatusResponse = yield call(socketEmit, data);
				if (res && res.confirmed) {
					yield put(modalReducers.setInfoUI({ visible: true, type: 'pending', description: 'pending_deposit' }));
				}
			}
			catch (e) {
				logger.log('error in socket response :', e);
			}
			clearData(STORAGE_KEYS.TRON_TRANSACTION_LS_KEY);
			break;
		}
		default:
			break;
	}
}

export default function* socketSaga(action: ISagaActionType<ISocketSagaPayload>) {
	yield all([
		fork(socketAction, action),
	]);
}
