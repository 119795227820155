import { IClaimDeclineBonusPayload } from './types';

const actions = {
	GET_BONUSES  : 'GET_BONUSES',
	DECLINE_BONUS: 'DECLINE_BONUS',
	CLAIM_BONUS  : 'CLAIM_BONUS',
	getBonuses   : () => {
		return {
			type: actions.GET_BONUSES,
		};
	},
	declineBonus: (data: IClaimDeclineBonusPayload) => {
		return {
			type: actions.DECLINE_BONUS,
			data,
		};
	},
	claimBonus: (data: IClaimDeclineBonusPayload) => {
		return {
			type: actions.CLAIM_BONUS,
			data,
		};
	},

};

export default actions;
