import { BonusTypes } from '../../constants/common';
import { NumberOrNull, StringOrNull } from '../../types/types';
import { dateType } from '../../components/history/filters/types';

export interface IHistoryInitState {
	casinoHistory           : ICasinoHistory;
	casinoSpins             : ICasinoSpins;
	casinoBonuses           : ICasinoBonuses;
	depositHistory          : IDepositHistory;
	withdrawalsHistory      : IWithdrawalHistory;
	balanceAdjustmentHistory: IBalanceAdjustmentHistory;
}

export interface IHistoryFilters<TStatus = NumberOrNull> {
	page            : number;
	limit           : number;
	place_date_from?: dateType | string;
	place_date_to?  : dateType | string;
	status_id?      : TStatus;
}

export interface HistoryBase<T = void> {
	data     : T[];
	dataCount: number;
	filters  : IHistoryFilters;
	UI: {
		loading: boolean;
	};
}
export interface ICasinoHistoryItem {
	id           : number;
	user_id      : number;
	money_type_id: MONEY_TYPE | null;
	amount       : number;
	won_amount   : number;
	game_id      : number | null;
	game_name    : string;
	status_id    : number;
	created      : string;
	provider_name: string;
}

export interface IBalanceAdjustmentItem {
	id         : number;
	payment_id : number;
	date       : string;
	amount     : number;
	status_id  : number;
	status_name: string;
	type_id    : number;
	bonus_type : StringOrNull;
	reason     : StringOrNull;

}
export interface ICasinoSpinItem {
	id               : number;
	user_id          : number;
	bonus_type_id    : BonusTypes;
	wallet_id        : number;
	bet_amount       : number;
	currency_code    : string;
	spins_total      : number;
	left_spins       : number;
	casino_game_name : string;
	wagering_turnover: number;
	won_amount       : number;
	expire_date      : string;
	status_id        : number;
	logo_url         : string;
	title            : string;
	description      : string;
	casino_game_id   : number;
	website_id       : number;
	pack_bonus_id    : number | null;
	created		  	     : string;

}
export interface ICasinoDepositItem {
	id          : number;
	payment_id  : number;
	payment_name: string;
	date        : string;
	amount      : number;
	status_id   : number;
	status_name : string;
	type_id     : number;
	bonus_type  : string | null;
	reason      : string | null;
}

export interface ICasinoFilters extends IHistoryFilters {}
export interface ICasinoHistory extends HistoryBase<ICasinoHistoryItem>{
	filters: ICasinoFilters;
}

export interface ICasinoSpins extends HistoryBase<ICasinoSpinItem>{}
export interface ICasinoBonusesItem {
	id                   : number;
	initial_balance      : number;
	balance              : number;
	wager                : number;
	expire_date          : string;
	bonus_type_id        : BonusTypes;
	given_date           : string;
	status_id            : number;
	comment              : string;
	market_id            : number | null;
	min_wagering_odd     : number | null;
	logo_url             : StringOrNull;
	title                : string;
	description          : string;
	stake_left_to_convert: number;
	stake_to_convert     : number;
}


export interface IHistoryWithdrawalItem {
	id          : number;
	date        : string;
	amount      : number;
	payment_name: string;
	status_name : WITHDRAW_STATUS_NAMES;
	reason      : StringOrNull;
}
export interface ICasinoBonuses extends HistoryBase<ICasinoBonusesItem>{}

export interface IDepositHistory extends HistoryBase<ICasinoDepositItem>{}

export interface IWithdrawalHistory extends HistoryBase<IHistoryWithdrawalItem>{}

export interface IBalanceAdjustmentHistory extends HistoryBase<IBalanceAdjustmentItem>{
	balanceAdjustmentExist: boolean;
}


export interface ISetFiltersPayload {
	type   : HISTORY_FILTERS;
	filters: IHistoryFilters;
}

export interface IFilterRefreshPayload {
	type  : HISTORY_FILTERS;
	filter: IHistoryFilters;
}

export interface IHistoryStore {
	casinoFilters           : ICasinoFilters;
	bonusesFilters          : IHistoryFilters;
	spinsFilters            : IHistoryFilters;
	depositFilters          : IHistoryFilters;
	withdrawalsFilters      : IHistoryFilters;
	balanceAdjustmentFilters: IHistoryFilters;
	isFirstLoad             : boolean;
}

export enum HISTORY_FILTERS {
	CASINO_HISTORY = 'casinoHistory',
	CASINO_SPINS = 'casinoSpins',
	CASINO_BONUSES = 'casinoBonuses',
	DEPOSIT_HISTORY = 'depositHistory',
	WITHDRAWALS_HISTORY = 'withdrawalsHistory',
	BALANCE_ADJUSTMENT_HISTORY = 'balanceAdjustmentHistory',
}

export enum CASINO_HISTORY_STATUSES {
	ACTIVE = 1,
	WON = 2,
	LOST = 3,
	REVERSED = 4,
}

export enum MONEY_TYPE {
	REAL = 1,
	VIRTUAL
}

export enum WITHDRAW_STATUS_NAMES {
	IN_REVIEW = 'IN_REVIEW',
	CANCELLED_BY_ADMIN = 'CANCELLED_BY_ADMIN',
	PENDING = 'PENDING',
}
