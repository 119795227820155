import React, { useState, Fragment, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ISearchFieldProps } from './types';
import './search-field.scss';


const SearchField: React.FC<ISearchFieldProps> = (props) => {
	const { placeholder, changeSearch, resetSearch, value, hint, ...rest } = props;
	const intl = useIntl();
	const [fieldBtn, setFieldBtn] = useState('icon_loop');

	const reset = () => {
		setFieldBtn('icon_loop');
		resetSearch();
	};

	const change = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value)
			setFieldBtn('icon_close');
		else
			setFieldBtn('icon_loop');
		changeSearch(value);
	};

	return (
		<Fragment>
			<input type="text" value={value} placeholder={intl.formatMessage({ id: placeholder })} onChange={change}{...rest}/>
			<button className={`clear_btn ${fieldBtn}`} onClick={reset}></button>
			{
				hint && value ? <div className="search_hint"><FormattedMessage id="search_results_for" values={{ term: <span className="search_keyword">{value}</span> }}/></div> : null
			}
		</Fragment>
	);
};

export default SearchField;
