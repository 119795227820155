import { apiRequest } from '../../service/service';
import { PartialConfigs } from '../../service/types';
import { TGetPromotionsPayload, TGetInnerPromotionsPayload } from './types';
class PromotionAPI {

	getPromotions = (params: TGetPromotionsPayload) => {
		const url = '/v1/content/promotion';
		const config: PartialConfigs<void, TGetPromotionsPayload> = {
			method: 'get',
			url   : url,
			params,
		};
		const result  = apiRequest(config);
		return result;
	};
	getPromotionInner = (params: TGetInnerPromotionsPayload) => {
		const url = `/v1/content/promotionbyalias/${params.alias}`;
		const config: PartialConfigs<void, TGetInnerPromotionsPayload> = {
			method: 'get',
			url   : url,
		};
		const result  = apiRequest(config);
		return result;
	};
}

export default PromotionAPI;