import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { Desktop } from '../../../helpers/devices';
import HeaderProfile from '../../header-profile';
import SidebarBonus from '../sidebar-bonus';
import HeaderLogin from '../../header-login';
import logoDark from '../../../resources/images/svg/lumos_full_logo-dark.svg';
import logoLight from '../../../resources/images/svg/full_logo.svg';
import { ROUTE_PATH } from '../../../routes/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { settingsReducers } from '../../../redux/settings/slice';
import gameActions from '../../../redux/games/actions';
import { getSettingsUI } from '../../../redux/settings/selectors';
import { profileSelectors } from '../../../redux/profile/selectors';
import { ICategory } from '../../../redux/games/types';
import { gamesSelectors } from '../../../redux/games/selectors';

const {
	selectAuthToken,
} = profileSelectors;
const {
	selectCategories,
} = gamesSelectors;

const SidebarMiddle: React.FC = () => {
	const authToken = useAppSelector(selectAuthToken);
	const dispatch = useAppDispatch();
	const categories: ICategory[] = useAppSelector(selectCategories);
	const { darkMode } = useAppSelector(getSettingsUI);
	const location = useLocation();
	const { pathname } = location;

	const onHomePage = () => {
		dispatch(settingsReducers.setSelectedCategory(0));
		if (categories && categories.length) {
			const [first] = categories;
			dispatch(gameActions.selectCategory(first.category));
			// Todo check this line is correct or top
			// dispatch(gameReducers.selectCategoryAction(first.category));
		}
	};

	const logo = darkMode ? logoDark : logoLight;

	return (
		<>
			<Desktop>
				<div onClick={onHomePage} className="main_logo">
					<Link to="/">
						<img src={logo} alt="" title=""/>
					</Link>
				</div>
			</Desktop>
			{
				authToken ?
					<Desktop>
						<div className="sign_block">
							<HeaderProfile/>
						</div>
					</Desktop> :
					<div className="sign_block">
						<Desktop> {/* commented in stage */}

							<Link className="tournament_btn icon_cup"
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-expect-error
								style={pathname.includes(`${ROUTE_PATH.root}${ROUTE_PATH.tournament}`) ? {
									backgroundColor: '#0069F8',
									color          : '#ffffff',
								} : null}
								to={ROUTE_PATH.tournament}>
								<span className='btn_text'><FormattedMessage id="tournaments"/></span>
							</Link>
						</Desktop>
						<SidebarBonus/>
						<HeaderLogin />
					</div>
			}
		</>
	);
};

export default SidebarMiddle;
