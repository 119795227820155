import { PAYMENT_PROVIDERS, PAYMENT_PROVIDERS_TYPE, PAYMENT_TYPES } from '../../constants/payments';
import { IAdaptedCard, ICard, PaymentFieldNames } from '../../components/withdraw/components/PaymentMethodFields/types';
import { IWithdrawBaseData, IWithdrawMethod, IWithdrawPaymentMethod } from './types';
import { objectCloneDeep } from '../../helpers/utils';
import { ErrorTypes } from '../deposit/utils';

const withdrawMethodFields = {
	available_deposit_allowed   : '',
	available_withdrawal_allowed: '',
	deposit_allowed             : false,
	description                 : '',
	favorite_amounts            : [],
	id                          : 0,
	logo_url                    : '',
	max_withdraw                : null,
	min_withdraw                : null,
	name                        : '',
	order_id                    : 0,
	payment_id                  : 0,
	website_id                  : 0,
	withdrawal_allowed          : false,
};

export const adaptWithdrawalMethods = (data: IWithdrawMethod) => {

	const clonedData = objectCloneDeep(data);

	if (!clonedData.payments) {
		return  void 0;
	}

	clonedData.payments.forEach(item => {

		const withdrawEntries = Object.entries(item);
		const [ key, value ] = withdrawEntries;
		if (!value && key) {
			// @ts-expect-error FIXME: fix
			item[key] = withdrawMethodFields[key];
		}
		if (item.id !== PAYMENT_PROVIDERS.tronlink) {
			return;
		}
		// item.isDepositedViaMethod = true;
	});

	return clonedData;
};


export const adaptCards = (data: ICard[]): Record<string, IAdaptedCard> => {
	return data.reduce((acc, item) => {
		acc[item.id] = {
			id     : item.id,
			account: item.number,
			name   : item.name,
			amount : {
				value: '',
				error: '',
			},
			loading: false,
			open   : false,

		};
		return acc;
	}, {} as Record<string, IAdaptedCard>);
};

const requiredFields = {
	[PAYMENT_PROVIDERS.tronlink]: [PaymentFieldNames.amount, PaymentFieldNames.ewallet_id],
	defaultCard                 : [PaymentFieldNames.amount, PaymentFieldNames.number, PaymentFieldNames.missingNumbers],
	defaultPhone                : [PaymentFieldNames.amount, PaymentFieldNames.phone],
	defaultWallet               : [PaymentFieldNames.amount],
};

const errorTypes = {
	minMax  : 'min_max_error',
	required: 'required_error',
};

export const generateErrorMap = (errors = [[]]) => {
	const map = {};
	errors.forEach(error => {
		const [errorKey, errorType] = error;
		map[errorKey] = errorType;
	});
	return map;
};

export const validateWithdraw = (baseData:  Partial<IWithdrawBaseData>, withdrawMethod:  IWithdrawPaymentMethod | null) => {
	if (!withdrawMethod) {
		return [];
	}
	const { payment_id: paymentID } = withdrawMethod;
	// At first we check payment ID (tronlink, netelar etc.)
	// and later payment type (wallet, card, phone)
	switch (paymentID) {
		case PAYMENT_PROVIDERS.tronlink: {
			return validateTronData(baseData, withdrawMethod);
		}
		default: {
			// return validateCardFields(baseData);
		}
	}

	if (PAYMENT_PROVIDERS_TYPE[paymentID] === PAYMENT_TYPES.card ) {
		return validateCardFields(baseData, withdrawMethod);
	}

	if (PAYMENT_PROVIDERS_TYPE[paymentID] === PAYMENT_TYPES.phone ) {
		return validatePhoneFields(baseData, withdrawMethod);
	}

	if (PAYMENT_PROVIDERS_TYPE[paymentID] === PAYMENT_TYPES.wallet ) {
		return validateWalletFields(baseData, withdrawMethod);
	}

	return validateCardFields(baseData, withdrawMethod);
};


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateCardFields = (baseData:  Partial<IWithdrawBaseData>, paymentMethod :  IWithdrawPaymentMethod) => {
	if (!baseData) {
		return [];
	}
	const baseDataResult: Partial<IWithdrawBaseData> & {
		[key in PaymentFieldNames]?: string;
	} = {
		...baseData,
	};
	const fields = [];
	requiredFields.defaultCard.forEach(field => {
		if (!baseDataResult[field]) {
			fields.push([field, errorTypes.required ]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(validMinMax);
	}

	return fields;
};

const validateMinMax = <T = object>(baseData:  Partial<IWithdrawBaseData>, paymentMethod: T &  { max_withdraw: number; min_withdraw: number}) => {
	if (!(paymentMethod.min_withdraw || paymentMethod.max_withdraw)) {
		return null;
	}

	const baseDataResult: Partial<IWithdrawBaseData> & {
		[key in PaymentFieldNames]?: string;
	} = {
		...baseData,
	};
	const fields = [];
	const amount = Number(baseDataResult[PaymentFieldNames.amount]);
	if (amount > paymentMethod.max_withdraw || amount < paymentMethod.min_withdraw) {
		fields.push(PaymentFieldNames.amount, errorTypes.minMax);
	}
	return fields.length ? fields : null;
};

const validateZero = (baseData: Partial<IWithdrawBaseData>) => {
	const fields = [];
	const amount = Number(baseData[PaymentFieldNames.amount]);

	if (amount === 0) {
		fields.push([PaymentFieldNames.amount, ErrorTypes.valueZero]);
	}

	return fields.length ? fields : null;
};


const validateWalletFields = (baseData:  Partial<IWithdrawBaseData>, paymentMethod:  IWithdrawPaymentMethod) => {
	const fields = [];
	const baseDataResult: Partial<IWithdrawBaseData> & {
		[key in PaymentFieldNames]?: string;
	} = {
		...baseData,
	};
	requiredFields.defaultWallet.forEach(field => {
		if (!baseDataResult[field]) {
			fields.push([field, errorTypes.required ]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(validMinMax);
	}
	return fields;
};

const validatePhoneFields = (baseData:  Partial<IWithdrawBaseData>, paymentMethod: IWithdrawPaymentMethod) => {
	const fields = [];
	const baseDataResult: Partial<IWithdrawBaseData> & {
		[key in PaymentFieldNames]?: string;
	} = {
		...baseData,
	};
	requiredFields.defaultPhone.forEach(field => {
		if (!baseDataResult[field]) {
			fields.push([field, errorTypes.required ]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(validMinMax);
	}
	return fields;
};

const validateTronData = (baseData:  Partial<IWithdrawBaseData>, paymentMethod: IWithdrawPaymentMethod) => {
	const fields = [];
	const baseDataResult: Partial<IWithdrawBaseData> & {
		[key in PaymentFieldNames]?: string;
	} = {
		...baseData,
	};
	requiredFields[PAYMENT_PROVIDERS.tronlink].forEach(field => {
		if (!baseDataResult[field]) {
			fields.push([field, errorTypes.required]);
		}
	});

	const validZero = validateZero(baseData);
	if (validZero) {
		fields.push(...validZero);
	}

	const validMinMax = validateMinMax(baseData, paymentMethod);
	if (validMinMax) {
		fields.push(validMinMax);
	}

	return fields;
};
