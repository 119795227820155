import { FC } from 'react';
import moment from 'moment';
import { typeDate, tzBrowsers } from './utils';
import { profileSelectors } from '../../../redux/profile/selectors';
import { useAppSelector } from '../../../hooks/storeHooks';
import { settingsInitial } from '../../../redux/settings/selectors';

interface IProps {
	date   	: Date | string;
	format ?: string;
	type   ?: string;
}

const {
	selectProfileData,
} = profileSelectors;
const Date:FC<IProps> = ({ date, format, type }) => {
	const { date_format, daysFormat, timeFormats } = useAppSelector(settingsInitial);
	const time_zone  = useAppSelector(selectProfileData).time_zone || tzBrowsers;
	const defaultFormat = format || date_format;

	if (!date){
		return null;
	}

	switch (type) {
		case typeDate.DAYS:
			return <span>{moment(date).utcOffset(time_zone).format(daysFormat)}</span>;
		case typeDate.TIMES:
			return <span>{moment(date).utcOffset(time_zone).format(timeFormats)}</span>;
		case typeDate.DIFF_DATE:
			return <span>{moment(date).utcOffset(time_zone).fromNow()}</span>;
		case typeDate.DATE_DURATION:
			return <span>{moment(date).fromNow(true)}</span>;
		default:
			return <span>{moment(date).utcOffset(time_zone).format(defaultFormat)}</span>;

	}
};

export default Date;
