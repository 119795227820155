import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Desktop } from '../../../helpers/devices';
import { useAppSelector } from '../../../hooks/storeHooks';
import { BANNER_GROUPS } from '../../../redux/banners/types';
import { getDeviceType } from '../../../redux/settings/utils';
import actions from '../../../redux/settings/actions';
import { BANNER_SECTIONS } from '../../../redux/settings/types';
import { profileSelectors } from '../../../redux/profile/selectors';
import { selectSettingsSidebarBlog } from '../../../redux/settings/selectors';
import './style.scss';

const {
	selectAuthToken,
} = profileSelectors;

const SidebarBlog: React.FC = () => {
	const dispatch = useDispatch();
	const sidebar_blog = useAppSelector(selectSettingsSidebarBlog);
	const authToken  = useAppSelector(selectAuthToken);

	const websiteId = process.env.REACT_APP_WEBSITE_ID;
	const channelID = getDeviceType();

	useEffect(  ()=>{
		if (websiteId) {
			dispatch(actions.getBannersAction({
				section: BANNER_SECTIONS.sidebarBlog,
				params : {
					group_name        : BANNER_GROUPS.sidebarBlog,
					request_from_front: true,
					website_id        : +websiteId,
					channel_id        : channelID,
				},
			}));
		}
	},[channelID, dispatch, websiteId, authToken]);

	if (!sidebar_blog || !sidebar_blog.length)
		return false;
	return (
		<Desktop>
			<div className="affiliate_business">
				<div className="button_block affiliate_block">
					{/*<a href="https://validator.antillephone.com/validate?domain=www.uaslots.com&seal_id=a0cac512223a2b05c5dff5630fbf7528c832e9aba40584c0c16015254b1cd0298b89edeb9ab1609c4d43ce0c98c657db&stamp=cc296cdcfabfc3f2c329f2ac965fe9a5" target='_blank' rel="noopener noreferrer">*/}
					{/* <span className="item_inner" dangerouslySetInnerHTML={{__html: sidebar_blog[0].script_code}}/> */}
					{/*</a>*/}
				</div>
				{/*<div className="button_block business_block">*/}
				{/*    <a href="https://validator.antillephone.com/validate?domain=www.uaslots.com&seal_id=a0cac512223a2b05c5dff5630fbf7528c832e9aba40584c0c16015254b1cd0298b89edeb9ab1609c4d43ce0c98c657db&stamp=cc296cdcfabfc3f2c329f2ac965fe9a5" target='_blank' rel="noopener noreferrer">*/}
				{/*        <span className="item_inner" dangerouslySetInnerHTML={{__html: sidebar_blog[1].script_code}}/>*/}
				{/*    </a>*/}
				{/*</div>*/}
			</div>
			{/*<div>{sidebar_blog[0].description}</div>*/}
		</Desktop>
	);
};

export default SidebarBlog;
