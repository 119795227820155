import { useEffect } from 'react';
import { settingsReducers } from '../redux/settings/slice';
import { useAppDispatch } from './storeHooks';
import { THEME_MODE } from './types';

export const useDarkMode = () => {
	const getStorage = localStorage.getItem('themeMode');
	const darkLocale = getStorage === THEME_MODE.DARK;
	const darkModeDefault =  window.matchMedia('(prefers-color-scheme: dark)').matches;
	const darkMode = getStorage ? darkLocale : darkModeDefault;
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (darkMode) {
			document.documentElement.setAttribute('data-theme', THEME_MODE.DARK);
			dispatch(settingsReducers.setSettingsUI({ darkMode: darkMode }));
			localStorage.setItem('themeMode', THEME_MODE.DARK);
		} else {
			document.documentElement.setAttribute('data-theme', THEME_MODE.LIGHT);
			dispatch(settingsReducers.setSettingsUI({ darkMode: darkMode }));
			localStorage.setItem('themeMode', THEME_MODE.LIGHT);
		}
		// eslint-disable-next-line
    }, [darkMode]);
};
