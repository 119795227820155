import { defaultLanguages } from './redux/settings/utils';
const API_URL = process.env.REACT_APP_API_PATH;
const API_AUROOM = process.env.REACT_APP_AUROOM;
const REACT_APP_WEBSITE_ID = Number(process.env.REACT_APP_WEBSITE_ID) || 0;
const APP_URL = `${window.location.origin}/${process.env.REACT_APP_GAME_EXIT_URL || ''}`;
const GAME_EXIT_URL = process.env.REACT_APP_GAME_EXIT_URL || '/';

const CASHIER_URL = 'profile/deposit';

export const DEV_SOCKET_PATH =
	process.env.REACT_APP_DEV_SOCKET_PATH || 'https://wsapi.bmakers.site';
export const PROD_SOCKET_PATH =
	process.env.REACT_APP_PROD_SOCKET_PATH || 'https://wsapi.bmakers.site';

export const SOCKET_URL =
	process.env.REACT_APP_MODE === 'development'? DEV_SOCKET_PATH: PROD_SOCKET_PATH;


const app_media_size = {
	mobile: 575,
	tablet: 1199,
	web   : Infinity,
	medium: [1200, 1439],
};

const user_document_types = {
	international_passport: 1,
	national_passport     : 2,
	driving_licence       : 3,
	bill                  : 4,
	other                 : 5,
	selfie                : 6,
};

const user_documents_default_type = 5;

const user_bonus_status_types = {
	3: 'expired',
	4: 'finished',
	5: 'granted',
	6: 'claimed',
	7: 'converted',
	8: 'declined',
};

const notif_count_per_page = 10;
const live_chat_license = 9501760;
export const pageTitle = 'Auroom';

const currencyList = {
	USD: '$',
	EUR: '€',
	RUB: '₽',
	GBP: '£',
	ARS: '$',
	BTC: 'B',
	TRY: '₺',
	KZT: '₸',
	UAH: '₴',
	PLN: 'zł',
	UZS: 'so\'m',
};
export const currencySignList = {
	1	: '$',
	2	: '₴',
	3	: '₽',
	4	: '€',
	6	: '£',
	8	: '₸',
	9 : '₺',
	15: '$',
	37: 'B',
	38: 'so\'m',
	40: 'zł',
};

const SEO_RESET_EXCLUDES = ['category'];

export {
	API_AUROOM,
	API_URL,
	GAME_EXIT_URL,
	APP_URL,
	CASHIER_URL,
	REACT_APP_WEBSITE_ID,
	app_media_size,
	currencyList,
	live_chat_license,
	notif_count_per_page,
	user_bonus_status_types,
	user_document_types,
	user_documents_default_type,
	SEO_RESET_EXCLUDES,
};

export const cookieExpirationDate = 365;
export const defaultLanguage = defaultLanguages[0];
export const debugMode = window.location.search.includes('debug') || Boolean(process.env.REACT_APP_DEBUG_MODE);
