import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects';
import { PAYMENT_PROVIDERS } from '../../constants/payments';
import {
	reStoreAuthToken,
	storeTronTransactionLSKey,
	storeWaitPayment,
} from '../../helpers/localStorageUtils';
import { tronDeepLink } from '../profile/utils';
import Api from './api';
import AuthApi from '../profile/api';
import { depositActions } from './actions';
import { modalReducers } from '../modals/slice';
import { withdrawActions } from '../withdraw/actions';
import { formatCategoriesUrl, openTronWalletOrMarket } from '../../helpers/utils';
import { generateErrorMap, validateDeposit } from './utils';
import { verificationTypes } from '../../constants/common';
import { logger } from '../../helpers/debugLogger';
import { cloneDeep, toInteger } from 'lodash';
import { depositReducers } from './slice';
import { WAIT_PAYMENT_STATUS } from '../../helpers/constants';
import { RootState } from '../store';
import { menuReducers } from '../menu/slice';
import {
	IAuthorizeTronResponse,
	IBaseError,
	IDepositMethods,
	IDepositResponse,
	IDepositStoreData,
	IDoDepositTronPayload,
	IPaymentMethodDeleteResponse,
	ISendSignedMessageResponse,
	ISendSignedTronTransactionPayload,
	ITronMobileDepositResponse,
} from './types';
import { PickOne } from '../../components/deposit/types';
import { ISagaActionType } from '../types';
import { AxiosApiResponse } from '../../service/types';

import { ErrorCodes } from '../../errors/ErrorCodes';
import { AxiosError } from 'axios';

const API = new Api();
const authAPI = new AuthApi();


const getStoreData = (state: RootState): IDepositStoreData => {
	const { Settings, Profile, Modals, Socket, Deposit } = cloneDeep(state);
	const currentLanguage							= Settings.current_language;
	const tronLoginDeepLink						= Profile.tronlink.params;
	const tronSignDeepLink						= Profile.tronlink.signParams;
	const tronTransactionDeepLink= Profile.tronlink.signTransaction;
	const origin           		= window.location.origin;
	const API_URL                 		= process.env.REACT_APP_API_PATH || '';
	if (currentLanguage) {
		tronLoginDeepLink.url 						= origin.concat(`/${currentLanguage.code}/tronlink`);
	}
	const tronAccounts								= Deposit.tronAccounts;

	return {
		languages             : Settings.languages,
		profileData           : Profile.profileData,
		profileDocBinaryStatus: Profile.profileDocBinaryStatus,
		profileDocs           : Profile.profileDocs,
		Login                 : Modals.Login,
		Register              : Modals.Register,
		sid                   : Socket.sid,
		baseData              : Deposit.baseData,
		paymentMethod         : Deposit.payment_method,
		tronLoginDeepLink,
		tronSignDeepLink,
		tronTransactionDeepLink,
		API_URL,
		tronAccounts,

	};
};

function* depositMethods() {
	yield takeEvery(depositActions.GET_DEPOSIT_METHODS, function* () {
		yield put(depositReducers.depositUIRefresh({ listLoading: true }));

		try {

			const response: AxiosApiResponse<IDepositMethods[]> = yield call(API.getDepositMethods);
			if (response && response.status === 200) {
				const { data } = response.data;
				if (data.length) {
					const sitemap = formatCategoriesUrl(data?.[0]?.payments || [], 'id', 'name');
					yield put(depositReducers.setDepositMethods(data?.[0])); // TODO: find alternative
					// @ts-expect-error FIXME: fix reducer payload type
					yield put(menuReducers.setSitemap(sitemap));
				}
			}
		} catch (e) {
			if (e instanceof AxiosError ) {
				const errorCode = toInteger(e.message);

				if (errorCode === ErrorCodes.DEPOSIT_BLOCKED) {
					yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
				}
			}
		}
		yield put(depositReducers.depositUIRefresh({ listLoading: false }));
	});
}

function* doDeposit() {
	yield takeEvery(depositActions.DO_DEPOSIT, function* () {
		try {
			const { baseData, paymentMethod }: IDepositStoreData = yield select(getStoreData);
			if (paymentMethod) {
				const errors = validateDeposit(baseData, paymentMethod);
				if (errors.length > 0) {
					const errorsMap = generateErrorMap(errors);

					yield put(depositReducers.baseErrorRefresh(errorsMap as PickOne<IBaseError>));
					return;
				}
			}


			yield put(depositReducers.depositUIRefresh({ loading: true }));

			// @ts-expect-error FIXME: fix doDeposit API arg types
			const response: AxiosApiResponse<string> = yield call(API.doDeposit, { params: baseData, method: paymentMethod?.payment_id });
			if (response && response.status === 200) {
				const { data } = response.data;
				const doc        = new DOMParser().parseFromString(data, 'text/html');
				const form       = doc.forms[0];

				if (form) {
					storeWaitPayment(WAIT_PAYMENT_STATUS.DEPOSIT);
					document.body.appendChild(form);
					form.submit();
					document.body.removeChild(form);
				} else {
					storeWaitPayment(WAIT_PAYMENT_STATUS.DEPOSIT);
					window.location.href = `${data}`;
				}
			}
		} catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = toInteger(e.message);
				if (errorCode === ErrorCodes.RESPONSIBLE_GAMBLING_TIME_OUT_LIMIT) return;

				const isPhone = errorCode === ErrorCodes.PHONE_VERIFICATION_REQUIRED;
				const isEmail = errorCode === ErrorCodes.EMAIL_VERIFICATION_REQUIRED;

				if (isPhone || isEmail){
					const linkTitle = isPhone ? verificationTypes.phone : verificationTypes.email;
					yield put(modalReducers.setInfoUI({
						visible    : true,
						type       : 'reject',
						description: ErrorCodes[errorCode],
						showLink   : true,
						linkTitle  : `verification_link_to_${linkTitle}`,
						hrefTo     : `profile?ver=${linkTitle}`,
					}));
				}
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}
		yield put(depositReducers.depositUIRefresh({ loading: false }));

	});
}


function* tronDeposit() {
	yield takeEvery(depositActions.DO_DEPOSIT_TRON, function* (action: ISagaActionType<IDoDepositTronPayload>) {
		const { tronAccounts, paymentMethod, baseData }: IDepositStoreData  = yield select(getStoreData);

		const hasErrors = validateDeposit(baseData, paymentMethod);
		if (hasErrors.length > 0) {
			const errorsMap = generateErrorMap(hasErrors);
			yield put(depositReducers.baseErrorRefresh(errorsMap));
			return;
		}

		const clonedAccounts = cloneDeep(tronAccounts);
		const { data } = action;

		const { tronAccount } = data;
		try {

			// set loader for deposit button
			if (tronAccount.temp) {
				yield put(depositReducers.tempAccountRefresh({ ...tronAccount, loading: true }));
			} else {
				const candidate = clonedAccounts[tronAccount.id];
				candidate.loading = true;
				clonedAccounts[candidate.id] = candidate;
				yield put(depositReducers.accountDataRefresh(clonedAccounts));
			}

			const normalizedData = {
				...data.params.params,
				amount   : data.params.amount,
				account  : data.params.account.account,
				card_save: Boolean(tronAccount.saveMethod),
				card_name: tronAccount.saveMethod ? tronAccount.name : '',
			};

			const method = data.method.id;
			// @ts-expect-error FIXME: fix doDeposit API arg types
			const response: AxiosApiResponse<IDepositResponse> = yield call(API.doDeposit, { params: normalizedData, method });

			if (response && response.status === 200) {
				const unSignedData      = response.data.data.trx_data;
				const signedTransaction : string = yield  window?.tronWeb?.trx.sign(unSignedData);
				yield put(depositActions.sendSignedMessage(signedTransaction));
				yield put(modalReducers.setInfoUI({ visible: true, type: 'pending', description: 'pending_deposit' }));

				yield put(withdrawActions.getCards(method));
			}
		} catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = toInteger(e.message);
				if (errorCode === ErrorCodes.RESPONSIBLE_GAMBLING_TIME_OUT_LIMIT) return;

				if (errorCode === ErrorCodes.INSUFFICIENT_BALANCE) {
					yield put(modalReducers.setInfoUI({
						visible    : true,
						type       : 'reject',
						description: ErrorCodes[errorCode],
					}));
					yield put(depositReducers.depositUIRefresh({ loading: false }));

					return;
				}
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			yield put(depositReducers.depositUIRefresh({ loading: false }));
		}

		if (tronAccount.temp) {
			yield put(depositReducers.tempAccountRefresh({ ...tronAccount, loading: false }));
		} else {
			const candidate = tronAccounts[tronAccount.id];
			candidate.loading = false;
			tronAccounts[candidate.id] = candidate;
			yield put(depositReducers.accountDataRefresh(tronAccounts));
		}


	});

}

function* tronDepositMobile() {
	yield takeEvery(depositActions.DO_DEPOSIT_TRON_MOBILE, function* (action: ISagaActionType<IDoDepositTronPayload>) {
		const { data } = action;
		const { tronAccount } = data;
		try {

			const normalizedData = {
				...data.params.params,
				amount   : data.params.amount,
				account  : data.params.account.account,
				card_save: Boolean(tronAccount.saveMethod),
				card_name: tronAccount.saveMethod ? tronAccount.name : '',
			};
			const method = data.method;

			// @ts-expect-error FIXME: fix doDeposit API arg types
			const response: AxiosApiResponse<ITronMobileDepositResponse> = yield call(API.doDeposit, normalizedData, method);
			const { tronTransactionDeepLink } = yield select(getStoreData);

			if (response && response.status === 200) {

				const actionID = response.data.data.trx_data.raw_data.data;
				const unSignedData                   = response.data.data.trx_data;
				tronTransactionDeepLink.data         = JSON.stringify(
					unSignedData);
				tronTransactionDeepLink.loginAddress = tronAccount.account;
				tronTransactionDeepLink.actionId = actionID;
				storeTronTransactionLSKey(actionID);

				const href = tronDeepLink(tronTransactionDeepLink);
				yield openTronWalletOrMarket(href, 1000);

			}
		} catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = toInteger(e.message);
				if (errorCode === ErrorCodes.RESPONSIBLE_GAMBLING_TIME_OUT_LIMIT) return;

				if (errorCode === ErrorCodes.INSUFFICIENT_BALANCE) {
					yield put(modalReducers.setInfoUI({
						visible    : true,
						type       : 'reject',
						description: ErrorCodes[errorCode],
					}));
					yield put(depositReducers.depositUIRefresh({ loading: false }));

					return;
				}

				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
			yield put(depositReducers.depositUIRefresh({ loading: false }));
		}
	});

}

function* sendSignedMessage() {
	yield takeEvery(depositActions.SEND_SIGNED_MESSAGE, function* (action: ISagaActionType<ISendSignedTronTransactionPayload>) {
		try {
			const { data } = action;

			const response: AxiosApiResponse<ISendSignedMessageResponse> = yield call(API.sendSignedMessage, data);

			if (response && response.status === 200) {
				logger.log(response);
			}
		} catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = toInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}

		yield put(depositReducers.depositUIRefresh({ loading: false }));
	});
}


function* authorizeTron() {
	yield takeEvery(depositActions.AUTHORIZE_TRON, function* () {
		try {
			const res: AxiosApiResponse<IAuthorizeTronResponse> = yield call(authAPI.sendWalletID, 'wallet');
			const { tronLoginDeepLink } = yield select(getStoreData);
			tronLoginDeepLink.url += '/' + reStoreAuthToken();
			tronLoginDeepLink.actionId += res.data.data.action_id;
			tronLoginDeepLink.callbackUrl +='/' + res.data.data.action_id;
			const href = tronDeepLink(tronLoginDeepLink);

			openTronWalletOrMarket(href);
		} catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = toInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}
		yield put(depositReducers.depositUIRefresh({ loading: false }));
	});

}

function* paymentMethodDelete() {
	yield takeEvery(depositActions.PAYMENT_METHOD_DELETE, function* (action: ISagaActionType<string>) {
		try {
			const { data: cardID } = action;
			const res: AxiosApiResponse<IPaymentMethodDeleteResponse>= yield call(API.deletePaymentMethod, cardID);

			if (res && res.status === 200) {
				yield put(withdrawActions.getCards(PAYMENT_PROVIDERS.tronlink));
			}

		} catch (e) {
			if (e instanceof AxiosError) {
				const errorCode = toInteger(e.message);
				yield put(modalReducers.setInfoUI({ visible: true, type: 'reject', description: ErrorCodes[errorCode] }));
			}
		}

		yield put(depositReducers.depositUIRefresh({ loading: false }));


	});

}

function* depositSaga() {
	yield all([
		fork(depositMethods),
		fork(doDeposit),
		fork(sendSignedMessage),
		fork(tronDeposit),
		fork(tronDepositMobile),
		fork(authorizeTron),
		fork(paymentMethodDelete),
	]);
}

export default depositSaga;
