import { Link, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import SearchPanel from '../../search-panel';
import LangSwitcher from '../../lang-switcher';
import Notifications from '../../notifications';
import HeaderLogin from '../../header-login';
import gamesActions from '../../../redux/games/actions';
import HeaderProfile from '../../header-profile/header-profile';
import Breadcrumb from '../../controls/breadcrumb';
import { Desktop, NotDesktop } from '../../../helpers/devices';
import { ROUTE_PATH } from '../../../routes/utils';
import { useRootAppNavigate } from '../../../hooks/useAppNavigate';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { settingsReducers } from '../../../redux/settings/slice';
import { getSettingsUI } from '../../../redux/settings/selectors';
import { ICategory } from '../../../redux/games/types';
import Clock from '../../controls/clock';
import DarkModeSwitch from './DarkModeSwitch/DarkModeSwitch';
import { profileSelectors } from '../../../redux/profile/selectors';
import { gamesSelectors } from '../../../redux/games/selectors';
import { modalReducers } from '../../../redux/modals/slice';
import './header.scss';

const {
	selectCategories,
} = gamesSelectors;

const PageHeader = () => {
	const { pathname } = useLocation();
	const categories: ICategory[] = useAppSelector(selectCategories);
	const authToken: string | null = useAppSelector(profileSelectors.selectAuthToken);
	const { darkMode } = useAppSelector(getSettingsUI);
	const navigate = useRootAppNavigate();
	const dispatch = useAppDispatch();
	const onHomePage = () => {
		dispatch(settingsReducers.setSelectedCategory(0));
		if (categories && categories.length) {
			const [first] = categories;
			dispatch(gamesActions.selectCategory(first.category));
			// Todo check this line is correct or top
			// dispatch(gameReducers.selectCategoryAction(first.category));
		}
	};

	const navigateSport = () => {
		if (authToken) {
			navigate(ROUTE_PATH.sports);
		} else {
			dispatch(modalReducers.setLoginUI({ visible: true, redirect: `/${ROUTE_PATH.sports}` }));
		}
	};

	const logoHeader = darkMode ? '/resources/images/svg/lumos_logo-dark.svg' : '/resources/images/svg/main_logo.svg';

	return (
		<>
			<div className="header">
				<div className="header_inner">
					<div className="custom_container">
						<Desktop>
							<SearchPanel/>
							<LangSwitcher/>
							<Clock/>
						</Desktop>
						<div className="main_logo">
							<Link to="/">
								<img src={logoHeader} alt="" title=""/>
							</Link>
						</div>
						<DarkModeSwitch/>
						<div className='icon_btns_block'>
							{!!authToken && <Notifications/>}
							<div className='tournament_btn'> {/* commented in stage */}
								<Button
									className={`icon_cup ${pathname.includes(ROUTE_PATH.tournament) ? 'active' : ''}`}
									onClick={() => navigate(ROUTE_PATH.tournament)}></Button>
							</div>
							<div className="sports_book_btn">
								<Button
									className={`icon_bet ${pathname.includes(ROUTE_PATH.sports) ? 'active' : ''}`}
									onClick={navigateSport}></Button>
							</div>
						</div>
						<NotDesktop>
							<div onClick={onHomePage} className="main_logo">
								<Link to={ROUTE_PATH.root}>
									<img src={logoHeader} alt="" title=""/>
								</Link>
							</div>
						</NotDesktop>
						{ authToken ? <HeaderProfile/> :
							<HeaderLogin />
						}
					</div>
				</div>
				<Breadcrumb excerpt={[ROUTE_PATH.root, ROUTE_PATH.errorPage, ROUTE_PATH.tournament]} showHome={true}/>
			</div>

		</>
	);
};


export default PageHeader;
