import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { bannerReducers } from './slice';
import { logger } from '../../helpers/debugLogger';
import BannersApi from './api';
import { AxiosApiResponse } from '../../service/types';
import { bannerActions } from './actions';
import { BANNER_GROUPS, IBanner, IBannerParams } from './types';
import { ISagaActionType } from '../types';
import { REACT_APP_WEBSITE_ID } from '../../config';
import { RootState } from '../store';

const API = new BannersApi();

const getStore = (store: RootState) => {
	return {
		channelID: store.Settings.channelID,
	};
};
function* getBanners() {
	yield takeEvery(bannerActions.GET_BANNERS, function* (action: ISagaActionType<BANNER_GROUPS>) {
		const { channelID } = yield select(getStore);
		try {
			const { data: bannerGroup } = action;
			const normalizedParams: IBannerParams = {
				group_name        : bannerGroup,
				channel_id        : channelID,
				website_id        : REACT_APP_WEBSITE_ID,
				request_from_front: true,
			};
			const result: AxiosApiResponse<IBanner[]> = yield call(API.getBanners, normalizedParams);
			if (result && result.status === 200) {
				yield put(bannerReducers.setBanners({ section: bannerGroup, data: result.data.data }));
			}
		} catch (e) {
			logger.log('e', e);
		}
	});
}


export default function* bannersRootSaga() {
	yield all([
		fork(getBanners),
	]);
}
